import React from "react";

const RightToLeftTabletArrow = ({ styleCLass }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="6"
      viewBox="0 0 72 6"
      fill="none"
      className={`${styleCLass}`}
    >
      <path d="M0 3L5 5.88675V0.113249L0 3Z" fill="#5e44ff" />

      <path
        class="moving_dots"
        d="M5 2.8567H71"
        stroke="url(#paint0_linear_left_header)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="0"
          y1="0"
          x2="178"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5E44FF" />
          <stop offset="0.5" stopColor="#BFBFBF" />
          <stop offset="1" stopColor="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RightToLeftTabletArrow;
