import React from 'react'
import "../../styles/components/CTAs.scss"
import BofuCTAForm from '../BofuCtaForm'

const BofuCTA1 = () => {
  return (
    <section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 bofu1_animated_border relative my-9  items-center py-5 sm:py-8 xl:pr-6">
      <section class="flex-[0.42] md:rounded-tr-[12px] rounded-tl-[20px] md:rounded-tl-[12px] rounded-tr-[20px] overflow-hidden">
        <img 
          src="https://images.prismic.io/encord/ZvzWprVsGrYSwQ2b_Bofu1.png?auto=format,compress" 
          height="747" 
          width="949" 
          alt="medical banner" 
          class="h-full md:rounded-tr-[12px] rounded-tl-[20px] md:rounded-tl-[12px] rounded-tr-[20px]"
        />
      </section>
    <section class="flex flex-col flex-[0.58] justify-center px-4 sm:px-0 gap-3">
    <div class="text-2xl sm:text-2.5xl text-purple-1100 font-manrope tracking-tightest_6 font-semibold">The leading AI data curation & labeling platform</div>
    
    <div class="text-sm -tracking-wider text-gray-600 font-normal font-inter">Curate the right data, annotate at speed, and identify and correct where models are underperforming, all on one platform</div> 
     <div class="flex ">
      <BofuCTAForm buttonText={`Get started`} redirectUrl="https://encord.com/try-it-free/?&utm_campaign=Blog-CTA-BOFU-1"/>
      </div>
    </section>
  </section>
  )
}

export default BofuCTA1