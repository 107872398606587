import React from "react";

export const PlatformIcon = ({stroke}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M4.82175 7.3125L1.6875 9L4.82175 10.6875M4.82175 7.3125L9 9.5625L13.1782 7.3125M4.82175 7.3125L1.6875 5.625L9 1.6875L16.3125 5.625L13.1782 7.3125M4.82175 10.6875L1.6875 12.375L9 16.3125L16.3125 12.375L13.1782 10.6875M4.82175 10.6875L9 12.9375L13.1782 10.6875M13.1782 7.3125L16.3125 9L13.1782 10.6875"
      stroke={stroke}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
