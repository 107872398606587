import { useRef, useEffect, useState } from "react";

export function useHeadsObserver() {
  const observer = useRef();
  const [activeId, setActiveId] = useState("h1");

  useEffect(() => {
    const handleObsever = (entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: "-10% 0% -35% 0px",
    });

    const elements = document.querySelectorAll("h2");
    elements.forEach((elem) => observer.current.observe(elem));

    const storedActiveId = localStorage.getItem("activeId");
    if (storedActiveId) {
      setActiveId(storedActiveId);
    }
    return () => {
      observer.current.disconnect();
      localStorage.removeItem("activeId");
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("activeId", activeId);
  }, [activeId]);

  return { activeId, setActiveId };
}
