import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/sectionDali.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const FeatureMunch = ({ slice }) => {
  const isDark = slice?.primary?.theme === "dark";
  return (
    <section className={`relative px-4  ${isDark ? "bg-purple-2300" : ""} `}>
      <article className="py-10 lg:py-20 lg:mx-11">
        <article className="relative max-w-6xl xl:px-0 xl:mx-auto sm:mx-6">
          <section className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-11 gap-y-12 ">
            {slice?.items?.map(
              (
                { caption_title, header_title, descriptor_text, image },
                index
              ) => {
                return (
                  <div
                    key={index}
                    className={`flex flex-col text-white lg:gap-y-6 ${
                      index == 0
                        ? "sm:col-span-2 lg:col-span-1 gap-y-6"
                        : "gap-y-3.5"
                    }`}
                  >
                    <div
                      className={`lg:py-0 flex justify-center ${isDark ? "bg-purple-2300" : " bg-[#8383834d]"}  ${
                        index != 0 && "sm:py-0 py-24"
                      } ${index === 2 && "sm:py-0 py-7"} `}
                      active_features
                    >
                      <GatsbyImage
                        image={getImage(image)}
                        loading="lazy"
                        alt={image?.alt || "feature munch image"}
                        className={`custom-gatsby sm:w-auto w-auto  lg:min-h-[380px] lg:max-h-[380px] ${index == 1 ? "max-w-[246px] sm:max-w-[225px] xl:max-w-[281px]" : index == 2 ? "max-w-[246px] sm:max-w-[225px] xl:max-w-[281px]" : ""} ${
                          index == 0
                            ? "lg:w-auto sm:min-h-max"
                            : "sm:min-h-[348px] lg:mx-auto mx-12"
                        } `}
                      />
                    </div>
                    <div>
                      <h3
                        className={`mb-4 text-xs font-medium uppercase font-inter lg:text-base tracking-widest_1  ${isDark ? "text-purple-350 " : "!text-purple-1500"}`}
                      >
                        {caption_title?.text}
                      </h3>
                      <h4
                        className={`sm:text-2xl text-2xl font-semibold mb-5 lg:h-18 font-manrope !tracking-tightest_5 ${index == 1 ? "sm:max-w-[205px] xl:max-w-full" : ""} ${isDark ? "text-white " : "text-purple-1100"}`}
                      >
                        {header_title?.text}
                      </h4>
                      <p
                        className={`text-base font-inter leading-6 tracking-tight_1 ${isDark ? "text-gray-2200" : "text-gray-600"}`}
                      >
                        {descriptor_text?.text}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </section>
        </article>
      </article>
    </section>
  );
};
