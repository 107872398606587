import React from "react";

class NewLeadForm extends React.Component {
  componentDidMount() {
    const { region, portalId, formId, targetId = "hubspotForm" } = this.props;
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: `#${targetId}`,
        });
      }
    });
  }
  render() {
    const { targetId = "hubspotForm", hideCommercialClass = false } =
      this.props;
    return (
      <div
        id={targetId}
        className={hideCommercialClass ? "" : "commercial-form"}
        style={{ minHeight: "100px" }}
      >
        {this.props?.loader ? <>{this.props?.loader}</> : <></>}
      </div>
    );
  }
}

export default NewLeadForm;
