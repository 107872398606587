import React from "react";
import UKflag from "../../../assets/uk.png";
import USAflag from "../../../assets/usa.png";

/**
 * JobListItem component represents a single job item.
 * @param {Object} props - The props object containing job details.
 * @param {string} props.name - The name of the job.
 * @param {string} props.link - The link to the job.
 * @returns {JSX.Element} JSX element representing the job list item.
 */
const JobListItem = ({ job }) => {
  const locations = [
    {
      location: job?.location_1?.text,
      link: job?.location_1_job_description_link?.url,
    },
    {
      location: job?.location_2?.text,
      link: job?.location_2_job_description_link?.url,
    },
  ];
  return (
    <div className="flex items-start sm:flex-row gap-5 sm:gap-1.5 flex-col justify-between py-5 px-5 sm:py-6 lg:p-6 transition duration-300 ease-in-out bg-white border border-gray-1500 rounded-md hover:bg-gray-100 sm:hover:shadow-lg sm:items-center">
      <div className="flex flex-col gap-1">
        <div className="text-lg font-semibold text-gray-2700 font-manrope -tracking-widest_3">
          {job?.job_title?.text}
        </div>
        <div className="flex items-center gap-2 text-xs font-medium tracking-tighter text-gray-1200 font-inter sm:text-sm sm:-tracking-wider">
          {job?.location_types}
          {job?.employement_types && (
            <div className="h-3 w-[1px] bg-gray-1200" />
          )}
          {job?.employement_types}
        </div>
      </div>

      <div className="flex gap-5 sm:gap-2.5 lg:gap-5 flex-col sm:flex-row ">
        {locations?.map(
          (location, index) =>
            location?.link && (
              <>
                {" "}
                <a
                  key={index}
                  target="_blank"
                  href={location?.link}
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 px-4 py-2 text-base font-semibold rounded-full font-manrope text-gray-2500 bg-gray-1600 tracking-tight_1 w-fit"
                >
                  <img
                    src={location?.location === "London" ? UKflag : USAflag}
                    alt={
                      location?.location === "London" ? "UK" : "USA" || "Country Flag"
                    }
                    height={23}
                    width={16}
                    loading="lazy"
                  />
                  <p>{location?.location}</p>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.67141 9.66797L12.9564 9.66797L6.80052 15.0117C6.70208 15.0979 6.76184 15.2578 6.89192 15.2578L8.44758 15.2578C8.51614 15.2578 8.58118 15.2332 8.63215 15.1893L15.2749 9.42539C15.3358 9.37265 15.3846 9.30745 15.418 9.23421C15.4514 9.16097 15.4687 9.08139 15.4687 9.00088C15.4687 8.92036 15.4514 8.84079 15.418 8.76755C15.3846 8.6943 15.3358 8.62911 15.2749 8.57637L8.59348 2.77734C8.56712 2.75449 8.53548 2.74219 8.50208 2.74219L6.89368 2.74219C6.7636 2.74219 6.70383 2.90391 6.80227 2.98828L12.9564 8.33203L2.67141 8.33203C2.59407 8.33203 2.53079 8.39531 2.53079 8.47266L2.53079 9.52734C2.53079 9.60469 2.59407 9.66797 2.67141 9.66797Z"
                      fill="#434343"
                    />
                  </svg>
                </a>
              </>
            )
        )}
      </div>
    </div>
  );
};

const JobListingMonet = ({ slice }) => {
  return (
    <section className="relative px-4 mx-auto sm:px-12 max-w-7xl">
      <div className="max-w-6xl mx-auto">
        <h3
          className="text-purple-1100 font-manrope text-2xl sm:text-2.5xl font-semibold tracking-tightest_5 sm:tracking-tightest_6 lg:mb-6"
          id="openJobs"
        >
          {slice?.primary?.section_header?.text}
        </h3>
        <div>
          <h4 className="py-2 sm:py-[18px] text-purple-1100 font-manrope text-xl sm:text-2xl  font-semibold tracking-tighter_1 sm:tracking-tighter_2">
            {slice?.primary?.department?.text}
          </h4>

          <>
            <div className="pt-2 pb-7 flex flex-col gap-3.5">
              {slice.items
                .slice()
                .sort((a, b) =>
                  a.job_title?.text.localeCompare(b.job_title?.text)
                )
                .map((job, jobIndex) => (
                  <JobListItem key={jobIndex} job={job} />
                ))}
            </div>
          </>
        </div>
      </div>
    </section>
  );
};

export default JobListingMonet;
