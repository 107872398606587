import React, { useContext, useEffect, useRef, useState } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/sectionDali.scss";
import SectionDaliCard from "../../Pieces/SectionDaliCard";

export const SectionDali = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  const containerRef = useRef(null);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(0);
  const cardWidth = 578;
  const scrollPadding = 50;
  const scrollDuration = 800;

  const smoothScroll = (targetPosition) => {
    if (containerRef.current) {
      const startPosition = containerRef.current.scrollLeft;
      const distance = targetPosition - startPosition;
      const startTime = performance.now();

      const scrollStep = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / scrollDuration, 1);
        const easeInOut = 0.5 * (1 - Math.cos(Math.PI * progress));

        containerRef.current.scrollLeft = startPosition + distance * easeInOut;

        if (elapsedTime < scrollDuration) {
          requestAnimationFrame(scrollStep);
        } else {
          containerRef.current.scrollLeft = targetPosition;
        }
      };

      requestAnimationFrame(scrollStep);
    }
  };

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const container = containerRef.current;
      const { scrollWidth, clientWidth, scrollLeft } = container;
      const isAtEnd = scrollLeft + clientWidth >= scrollWidth;

      setIsOverflowing(scrollWidth > clientWidth && !isAtEnd);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    containerRef?.current?.addEventListener("scroll", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
      containerRef?.current?.removeEventListener("scroll", checkOverflow);
    };
  }, []);

  useEffect(() => {
    if (hoveredCardIndex !== null) {
      const cardOffset = cardWidth * hoveredCardIndex;
      const scrollAmount = cardOffset - scrollPadding;

      smoothScroll(scrollAmount);
    }
  }, [hoveredCardIndex]);

  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="py-10 lg:pb-0 lg:pt-20 lg:mx-11">
        <HeadingSection
          data={slice?.primary}
          toggleModal={toggleModal}
          sectionStyle="lg:!pb-10 sm:max-w-[640px] md:max-w-[1002px]"
          headingStyle="sm:text-3.25xl !leading-10 -tracking-[0.9px]"
          descriptionStyle="lg:text-lg !-tracking-[0.45px]"
          textAreaStyle="gap-4"
        />
        <section
          ref={containerRef}
          className={`flex gap-3 flex-row overflow-x-auto lg:overflow-hidden lg:min-h-[372px] duration-600 ease-linear hideScrollBar scroll-smooth ${isOverflowing ? "dali_slide_opacity_right" : ""}`}
        >
          {slice?.items.map((data, index) => (
            <SectionDaliCard
              key={index}
              cardData={data}
              index={index}
              isHovered={index === hoveredCardIndex}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(0)}
            />
          ))}
        </section>
      </article>
    </section>
  );
};
