import React, { useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { Link } from "gatsby";

/**
 * @summary On the specific pages, this component appears at the top of the header.
 * @param {Object} {location} from the page
 * @returns JSX.Element
 */
const CustomNewsBanner = ({
  location,
  localStorageVariableName = "closedFor",
}) => {
  const [announcementBannerOpen, setAnnouncementBannerOpen] = useState(false);
  const [_closedFor, _setClosedFor] = useState("[]");

  /**
   * @summary To prevent reopening on the specific page, this solution disables
   * the banner status and saves the pathname in localStorage.
   */
  const handleClose = () => {
    const _closedFor = localStorage.getItem(localStorageVariableName) || "[]";
    const closedFor = JSON.parse(_closedFor);
    if (!closedFor.includes(location.pathname)) {
      localStorage.setItem(
        localStorageVariableName,
        JSON.stringify([...closedFor, location?.pathname])
      );
    }
    setTimeout(() => {
      setAnnouncementBannerOpen(false);
    }, 600);
  };

  useEffect(() => {
    _setClosedFor(JSON.parse(localStorage[localStorageVariableName] || `[]`));
    setAnnouncementBannerOpen(true);
  }, []);

  return (
    <div
      className={`relative bg-indigo-600 customBanner ${announcementBannerOpen && !_closedFor?.includes(location?.pathname)
        ? "show"
        : "hide"
        }`}
    >
      <div className="px-3 py-3 mx-auto max-w-7xl sm:px-4 lg:px-6">
        <div className="sm:pr-12 pr-6 max-[320px]:text-xs text-sm lg:text-base sm:text-center sm:px-12">
          <Link
            to="https://encord.com/webinar/sam2-webinar/?utm_medium=display&utm_source=direct-mail&utm_campaign=SAM2homepage"
            className="text-white"
            target="_blank"
          >
            <p className="justify-center gap-1 font-bold text-center text-white md:flex md:gap-0">
              <span className="sm:hidden">
                🚨 Webinar: How to Fine-tune SAM 2 - <span className="underline">Sign Up Here</span>
              </span>
              <span className="hidden sm:inline">
                🚨 Webinar: How to Fine-tune SAM 2 - <span className="underline">Sign Up Here</span>
              </span>
              <span className="ml-1 sm:inline-block pr-0.5">🚨</span>
            </p>
          </Link>
        </div>
        <div className="absolute inset-y-0 right-0 pt-0.5 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button
            type="button"
            className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none"
            onClick={handleClose}
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomNewsBanner;
