import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export default function SEO({
  title,
  description,
  image,
  twitterHandle,
  url,
  pathname,
  children,
}) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${defaultImage}`,
    url: url || `${siteUrl}${pathname || ``}`,
    twitterUsername: twitterUsername || twitterHandle,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={url} />
      <meta property="og:image:width" content="2167" />
      <meta property="og:image:height" content="1078" />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image:type" content="image/*" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content="@encord_team" />
      <meta name="twitter:creator" content="@encord_team" />
      {children}
    </>
  );
}
