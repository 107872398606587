import React from "react";
import PropTypes from "prop-types";
import CustomButton from "../../CustomButton";

const NextArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
      fill="#434343"
    />
  </svg>
);

const SectionDaliCard = ({
  cardData,
  index,
  isHovered,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      key={index}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`p-3.5 sm:p-5 justify-between min-w-[250px] sm:min-w-[534px] lg:h-fit sm:max-h-fit lg:min-w-[unset] flex flex-nowrap ease-linear duration-700 transition-all group bg-white lg:bg-transparent rounded-2.5xl flex-1 ${
        isHovered
          ? "lg:max-w-[550px] lg:bg-white hover:border outline-gray-1600 rounded-2.5xl flex-[2]"
          : ""
      }`}
    >
      <div className="flex flex-col gap-5 sm:flex-row">
        {cardData?.image?.url && (
          <img
            src={cardData?.image?.url}
            loading="lazy"
            alt="avatar"
            className={`w-0 lg:h-[250px] max-h-[250px] delay-200 duration-[1.2s] rounded-lg hidden lg:block ${
              isHovered ? "w-[180px]" : ""
            }`}
          />
        )}
        {cardData?.image?.url && (
          <img
            src={cardData?.image?.url}
            loading="lazy"
            alt="avatar"
            className=" sm:h-[250px] sm:max-h-[250px] rounded-lg sm:w-[180px] lg:hidden block"
          />
        )}
        <div className="flex flex-col h-full">
          <div className="flex items-center gap-1">
            {cardData?.hovered_icon?.url && (
              <img
                src={
                  isHovered
                    ? cardData?.hovered_icon?.url
                    : cardData?.caption_title_icon?.url
                }
                loading="lazy"
                alt="avatar"
                className="hidden size-3 lg:block"
              />
            )}
            {cardData?.hovered_icon?.url && (
              <img
                src={
                  cardData?.hovered_icon?.url ||
                  cardData?.caption_title_icon?.url
                }
                loading="lazy"
                alt="avatar"
                className="size-3 lg:hidden"
              />
            )}
            <p
              className={`text-xs font-semibold font-manrope -tracking-wide ${
                isHovered
                  ? "text-purple-1100"
                  : "text-purple-1100 lg:text-gray-600"
              }`}
            >
              {cardData?.caption_title?.text}
            </p>
          </div>
          <h1
            className={`font-semibold font-manrope tracking-tighter_1 delay-1000 lg:max-w-[280px] lg:tracking-tighter_2 py-3.5 text-xl text-purple-1100 lg:text-gray-2700 ${
              isHovered
                ? "lg:text-purple-1100"
                : "text-purple-1100 lg:text-gray-2700"
            }`}
          >
            {cardData?.title?.text}
          </h1>
          <p
            className={`text-sm font-normal delay-1000 font-inter tracking-tighter_1 lg:-tracking-wider w-full mb-5 lg:mb-0 ${
              isHovered
                ? "text-gray-2500"
                : "text-gray-2500 lg:text-gray-600 lg:line-clamp-3"
            }`}
          >
            {cardData?.descriptor_text?.text}
          </p>
          {cardData?.cta_title?.text && (
            <div className="mt-auto">
              <CustomButton
                text={cardData?.cta_title?.text}
                link={cardData?.cta_link?.url}
                icon={<NextArrow />}
                buttonClass="bg-gray-1500 hover:text-gray-2700 hover:bg-gray-1600 text-gray-2500 mt-auto self-end"
                direction="right"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SectionDaliCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isHovered: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default SectionDaliCard;
