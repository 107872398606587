import React, { useEffect, useState } from "react";
import CellMonetCard from "../../Pieces/CellMonetCard";
import Pagination from "../../Pagination";
import { useMediaQuery } from "react-responsive";
import "../../../styles/components/Slices/cellMonet.scss";

const LIMIT_DESKTOP = 9;
const LIMIT_TABLET = 8;

const CellMonet = ({ slice }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const filteredPress = slice?.items;
  const LIMIT = isTablet ? LIMIT_TABLET : LIMIT_DESKTOP;
  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const sortedPress = [...filteredPress].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const totalPages = Math.ceil(sortedPress.length / LIMIT) || 0;
  const _filteredPress = sortedPress.slice(
    (currentPage - 1) * LIMIT,
    currentPage * LIMIT
  );
  return (
    <>
      {slice?.items[0]?.title?.text && (
        <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
          <article className="relative py-10 lg:mx-11 ">
            <section className="grid max-w-6xl grid-cols-1 gap-6 mx-auto sm:grid-cols-2 lg:grid-cols-3 custom_grid_coloumns">
              {!_filteredPress.length && (
                <p className="mb-4 text-2xl font-bold text-center">
                  No Press Found
                </p>
              )}
              {_filteredPress.map((press, index) => {
                return (
                  <CellMonetCard
                    pressData={press}
                    key={`press_card_${index}`}
                  />
                );
              })}
            </section>
            <div className="flex justify-center mt-6 sm:mt-14 lg:mt-16">
              {!!_filteredPress?.length && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={LIMIT}
                  onPageChange={(page) => setCurrentPage(page)}
                  siblingCount={isMobile ? 0 : 1}
                />
              )}
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default CellMonet;
