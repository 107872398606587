import React from "react";
import HeroSupportingAssetMonetMarquee from "../../Pieces/HeroSupportingAssetMonetMarquee";

const HeroSupportingAssetMonet = ({ slice }) => {
  return (
    <div>
      <HeroSupportingAssetMonetMarquee data={slice?.items} duration={120000} />
    </div>
  );
};

export default HeroSupportingAssetMonet;
