import React from "react";
import "swiper/css";
import "../../../styles/page/TestimonialTurnerCard.scss";
import TestimonialTurnerCard from "../../Pieces/TestimonialTurnerCard";

const TestimonialTurner = ({ slice }) => {
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <div className="flex gap-5 py-10 mx-auto overflow-x-auto lg:w-auto scroll-smooth no-scrollbar tracking-tight_1 lg:py-20 lg:mx-11">
        {slice?.items.map((data, index) => (
          <TestimonialTurnerCard cardData={data} index={index} />
        ))}
      </div>
    </section>
  );
};

export default TestimonialTurner;
