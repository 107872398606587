import React, { useEffect, useRef, useState } from "react";
import "../../../styles/components/Slices/CompanyBlogs.scss";
import CareerBlogCard from "../../CareerBlogCard";
import { ParentCard } from "../../career_page/comapny_parentCard";

const Company = ({ slice, location }) => {
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showBackIcon, setShowBackIcon] = useState(false);
  const [showForwardIcon, setShowForwardIcon] = useState(true);

  useEffect(() => {
    const checkOverflow = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollWidth, clientWidth, scrollLeft } = container;
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth;

        setIsOverflowing(scrollWidth > clientWidth && !isAtEnd);
        setShowBackIcon(scrollLeft > 0);
        setShowForwardIcon(!isAtEnd);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    containerRef?.current?.addEventListener("scroll", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
      containerRef?.current?.removeEventListener("scroll", checkOverflow);
    };
  }, []);

  const _data = slice?.items;
  const PrimaryData = slice?.primary;
  const emptyParentCard = !!(
    PrimaryData?.card_text?.text || PrimaryData?.section_title?.text
  );
  const emptyBlogCards = !!(
    _data[0]?.company_blogs?.document?.data?.image?.gatsbyImageData?.images
      ?.fallback?.src || _data[0]?.company_blogs?.document?.data?.title?.text
  );
  const emptySLice = !!(emptyParentCard && emptyBlogCards);
  return (
    <>
      {emptySLice && (
        <section className="max-w-[1300px] xl:pl-[74px] overflow-x-auto flex flex-col gap-6 mx-auto py-10 pl-4 sm:pl-12 xl:px-0">
          <div className="flex flex-col gap-2.5">
            {PrimaryData?.section_title?.text && (
              <p className="text-2xl sm:text-2.5xl font-manrope text-purple-1100 font-semibold -tracking-[1.2px] sm:-tracking-[1.6px]">
                {PrimaryData?.section_title?.text}
              </p>
            )}
            {PrimaryData?.section_description?.text && (
              <p className="text-gray-600 font-normal lg:max-w-6xl xl:pr-0 sm:pr-12 pr-4 text-base md:max-w-full max-w-[288px] xs:max-w-full sm:max-w-[648px] sm:text-lg overflow-x-auto scroll-smooth -tracking-[0.45px]">
                {PrimaryData?.section_description?.text}
              </p>
            )}
          </div>

          <div className="flex flex-row gap-3 sm:gap-6">
            {emptyParentCard && (
              <div className="hidden md:flex">
                <ParentCard
                  data={PrimaryData}
                  containerRef={containerRef}
                  showBackIcon={showBackIcon}
                  showForwardIcon={showForwardIcon}
                />
              </div>
            )}

            <div
              ref={containerRef}
              className={`flex flex-row gap-3 sm:gap-6 overflow-x-auto overflow-y-hidden right_padding no-scrollbar ${
                isOverflowing ? "Blog_slide_opacity_right" : " "
              }`}
            >
              {emptyParentCard && (
                <div className="flex md:hidden">
                  <ParentCard
                    data={PrimaryData}
                    containerRef={containerRef}
                    showBackIcon={showBackIcon}
                    showForwardIcon={showForwardIcon}
                  />
                </div>
              )}
              {emptyBlogCards &&
                _data.map((blog, index) => (
                  <CareerBlogCard
                    location={location}
                    key={`blog_card_${index}`}
                    blog={blog}
                  />
                ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Company;