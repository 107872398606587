import React, { useRef } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowDown } from "../assets/icons/arrowDownIcon";
import CustomLink from "../components/CustomLink";
import {
  Aerospace,
  Healthcare,
  ComputerVision,
  LifeSciences,
  Technology,
  Sports,
  Energy,
  Agriculture,
  Retail,
  Media,
  Manufacturing,
  Insurance,
  Logistics,
} from "../assets/solution-header-button";
import { headerTheme } from "./Header";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const industries = [
  {
    text: "Aerospace & Defense",
    icon: <Aerospace />,
    href: "/industry/defense/",
  },
  {
    text: "Agriculture",
    icon: <Agriculture />,
    href: "/industry/agriculture/",
  },
  {
    text: "Computer Vision",
    icon: <ComputerVision />,
    href: "/industry/computer-vision/",
  },
  {
    text: "Energy",
    icon: <Energy />,
    href: "/industry/energy/",
  },
  {
    text: "Healthcare & Medical",
    icon: <Healthcare />,
    href: "/industry/healthcare/",
  },
  {
    text: "Insurance",
    icon: <Insurance />,
    href: "/industry/insurance/",
  },
  {
    text: "Life Sciences & Biotech",
    icon: <LifeSciences />,
    href: "/industry/life-sciences/",
  },
  {
    text: "Logistics",
    icon: <Logistics />,
    href: "/industry/logistics/",
  },
  {
    text: "Manufacturing",
    icon: <Manufacturing />,
    href: "/industry/manufacturing/",
  },
  {
    text: "Media, Gaming & Entertainment",
    icon: <Media />,
    href: "/industry/media-entertainment/",
  },
  {
    text: "Retail & E-commerce",
    icon: <Retail />,
    href: "/industry/retail/",
  },
  {
    text: "Sports",
    icon: <Sports />,
    href: "/industry/sports/",
  },
  {
    text: "Technology & Software",
    icon: <Technology />,
    href: "/industry/technology-software/",
  },
];

const link = {
  text: "Join 10k+ practitioners advancing computer vision in their fields.",
  href: "/try-it-free/",
};

export default function SolutionsHeaderButton({
  theme,
  handleEnter,
  handleLeave,
  ref,
  isHover,
}) {
  const timeoutDuration = 120;

  return (
    <Popover
      className="relative"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      ref={ref}
    >
      {({ open }) => (
        <>
          <div>
            <Popover.Button
              className={classNames(
                isHover
                  ? headerTheme[theme].hoverTextColor
                  : headerTheme[theme].textColor,
                "group rounded-md inline-flex gap-2 items-center text-sm lgxl_mid:text-base font-medium focus:outline-none"
              )}
            >
              <span>Industries</span>
              <ArrowDown
                fill={
                  isHover
                    ? headerTheme[theme].hoverIcon
                    : headerTheme[theme].icon
                }
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 left-1/4 transform -translate-x-1/4 mt-3 px-2 w-screen max-w-5xl sm:px-0">
                <div
                  className="rounded-lg border border-gray-200 ring-1 ring-black ring-opacity-5"
                  style={{
                    boxShadow:
                      "0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <nav
                    className="p-7 rounded-lg bg-white"
                    aria-labelledby="solutions-heading"
                  >
                    <h4 className="text-sm font-medium text-gray-1200 uppercase">
                      for industries
                    </h4>
                    <ul className="mt-2.5 border-gray-1600  columns-3">
                      {industries.map(({ text, href, icon }, index) => (
                        <li className="group">
                          <CustomLink
                            to={href}
                            key={index}
                            className="flex pl-2 py-2 gap-x-3.5 items-start rounded-lg  group-hover:bg-gray-1500 outline-none transition ease-in-out duration-150"
                          >
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-5 text-white hover:fill-pruple-500">
                              {icon}
                            </div>
                            <p className="text-base font-medium text-gray-600">
                              {text}
                            </p>
                          </CustomLink>
                        </li>
                      ))}
                      <li className="group w-60">
                        <CustomLink
                          to={link.href}
                          className="flex pt-1.5 gap-x-4 items-start rounded-lg group-hover:underline outline-none transition ease-in-out duration-150"
                        >
                          <p className="text-sm font-medium text-gray-1200">
                            {link.text}
                          </p>
                        </CustomLink>
                      </li>
                    </ul>
                  </nav>

                  {/* <DicomNavigationBanner /> */}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
}
