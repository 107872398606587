import axios from "axios";
import React, { useState } from "react";
import { HUBSPOT_PROXY_URL } from "../constants";
import { footerTheme } from "../partials/Footer";

const SignUpForm = ({ subscribe, theme= "default"  }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submittedState, setSubmittedState] = useState("none");

  const subscribeToNewsLetter = async (e) => {
    try {
      e.preventDefault();
      setMessage("");
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];

      if (email.length < 3) {
        return false;
      }

      const requestPayload = {
        fields: [
          {
            objectTypeId: "0-1",
            name: "email",
            value: email,
          },
        ],
      };
      if (hubspotutk) {
        Object.assign(requestPayload, {
          context: {
            hutk: hubspotutk,
          },
        });
      }
      const res = await axios.post(
        HUBSPOT_PROXY_URL,
        Object.assign(requestPayload, {
          portalId: "25381551",
          formId: "7a9fb3d8-2bac-4efd-9c4c-186560ce075c",
        })
      );

      setSubmittedState("success");
      setEmail("");
      setMessage(res?.data?.inlineMessage || "Thanks for subscribing!");
    } catch (error) {
      setMessage("");
      setSubmittedState("error");
    }
  };

  return (
    <div>
      <div className="flex flex-wrap mb-4">
        <div className="w-full">
          <label className="block text-sm sr-only" htmlFor="newsletter">
            Email
          </label>
          <form
            className={`${footerTheme[theme]?.subFormContainerCalsses}`}
            onSubmit={(e) => subscribeToNewsLetter(e)}
          >
            <input
              type="email"
              name="EMAIL"
              className={`${footerTheme[theme]?.subFormInputCalsses}`}
              placeholder="Your work email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className={`${footerTheme[theme]?.subBtnClasses}`}
              aria-label="Subscribe"
            >
              <span
                className={`${
                  footerTheme[theme]?.isDivider
                    ? "hidden"
                    : "absolute inset-0 right-auto w-px my-2 -ml-px bg-gray-300"
                }`}
                aria-hidden="true"
              />
              <svg
                className={`${footerTheme[theme]?.subBtnIconClasses}`}
                viewBox="0 0 12 12"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </svg>
            </button>
          </form>
          {submittedState === "success" && (
            <p className="mt-2 text-sm text-green-600">
              {subscribe ? (
                <div>
                  {message ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message,
                      }}
                    />
                  ) : (
                    "Thanks for subscribing!"
                  )}
                </div>
              ) : (
                "Thanks for subscribing!"
              )}
            </p>
          )}
          {submittedState === "error" && (
            <p className="mt-2 text-sm text-red-600">Something went wrong</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
