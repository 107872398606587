import React, { useRef } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import {
  AtSymbolIcon,
  CalendarIcon,
  OfficeBuildingIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";
import { ArrowDown } from "../assets/icons/arrowDownIcon";
import CustomLink from "../components/CustomLink";
import { headerTheme } from "./Header";
import { ForwardArrow } from "../assets/icons";

export const company = [
  {
    name: "About us",
    href: "/about-us/",
    icon: OfficeBuildingIcon,
  },
  {
    name: "Careers",
    href: "/careers/",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Press",
    href: "/press/",
    icon: CalendarIcon,
  },
  {
    name: "Contact us",
    href: "/contact-us/",
    icon: AtSymbolIcon,
  },
];

// const recentPosts = [
//   {
//     id: 1,
//     name: "Introduction to micro-models or: how I learned to stop worrying and love overfitting",
//     href: "https://encord.com/blog/introduction-to-micro-models-or-how-i-learned-to-stop-worrying-and-love-overfitting/",
//   },
//   {
//     id: 2,
//     name: "Encord raises $12.5M in fresh Series A funding",
//     href: "https://www.businesswire.com/news/home/20211013005084/en/",
//   },
//   {
//     id: 3,
//     name: "Why you should ditch your in-house training data tools (and avoid building your own)",
//     href: "https://encord.com/blog/why-you-should-ditch-your-in-house-training-data-tools-and-avoid-building-your-own/",
//   },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CompanyHeaderButton({
  theme,
  handleEnter,
  handleLeave,
  ref,
  isHover,
}) {
  return (
    <Popover
      className="relative"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      ref={ref}
    >
      {({ open }) => (
        <>
          <div>
            <Popover.Button
              className={classNames(
                isHover
                  ? headerTheme[theme].hoverTextColor
                  : headerTheme[theme].textColor,
                "group rounded-md inline-flex gap-2 items-center text-sm lgxl_mid:text-base font-medium focus:outline-none"
              )}
            >
              <span>Company</span>
              <ArrowDown
                fill={
                  isHover
                    ? headerTheme[theme].hoverIcon
                    : headerTheme[theme].icon
                }
              />
            </Popover.Button>

            <Transition
              show={isHover}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-2 mt-3 transform -translate-x-1/2 left-40 sm:px-0">
                <div
                  className="overflow-hidden border border-gray-200 max-w-[200px] rounded-lg ring-1 ring-black ring-opacity-5"
                  style={{
                    boxShadow:
                      "0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div className="relative grid gap-6 p-2 bg-white sm:gap-6 sm:p-5">
                    <ul className="space-y-6">
                      {company.map((item) => (
                        <li key={item.name} className="flow-root group">
                          {item.external ? (
                            <a
                              href={item.href}
                              className="-m-3 p-2 flex items-center justify-between rounded-md text-base font-medium navbar-hover-bg text-gray-900  outline-none transition ease-in-out duration-150"
                            >
                              <div className="flex items-center gap-1.5">
                                <item.icon
                                  color="#434343"
                                  className="flex-shrink-0 w-5 h-5 text-gray-2500 stroke-gray-2500 group-hover:stroke-purple-1500"
                                  aria-hidden="true"
                                />
                                <p className="text-base text-gray-2500 group-hover:text-gray-2700 font-medium tracking-tighter_3">
                                  {item.name}
                                </p>
                              </div>
                              <ForwardArrow
                                fill={"#5E44FF"}
                                className="hidden group-hover:inline-block"
                              />
                            </a>
                          ) : (
                            <CustomLink
                              to={item.href}
                              className="-m-3 p-2 flex items-center justify-between rounded-md text-base font-medium text-gray-900 navbar-hover-bg outline-none transition ease-in-out duration-150"
                            >
                              <div className="flex items-center gap-1.5">
                                <item.icon
                                  className="flex-shrink-0 w-5 h-5  stroke-gray-2500 group-hover:stroke-purple-1500"
                                  aria-hidden="true"
                                />
                                <p className="text-base text-gray-2500 group-hover:text-gray-2700 font-medium tracking-tighter_3">
                                  {item.name}
                                </p>
                              </div>
                              <ForwardArrow
                                fill={"#5E44FF"}
                                className="hidden group-hover:inline-block"
                              />
                            </CustomLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* <div className="px-5 py-5 bg-[#F5F5F5] sm:px-8 sm:py-8">
                  <div>
                    <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                      Recent Posts
                    </h3>
                    <ul className="mt-4 space-y-4">
                      {recentPosts.map((post) => (
                        <li key={post.id} className="text-base truncate">
                          <a
                            href={post.href}
                            className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {post.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-5 text-sm">
                    <CustomLink
                      to="/blog/"
                      className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                    >
                      {" "}
                      View all posts <span aria-hidden="true">&rarr;</span>
                    </CustomLink>
                  </div>
                </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
}
