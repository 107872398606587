import React, { useState, useEffect } from "react";

const FeatureDaliCard = ({ data, index, location }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  return (
    <div
      key={index}
      onMouseEnter={() => setHoveredCardIndex(index)}
      onMouseLeave={() => setHoveredCardIndex(null)}
      className="grid grid-rows-subgrid row-span-2 gap-2.5"
    >
      <div>
        <img
          src={
            hoveredCardIndex !== null ? data?.hover_icon?.url : data?.icon?.url
          }
          width={30}
          height={30}
          className="mb-3.5"
          loading="lazy"
          alt="feature dali card image"
        />
        <div
          className={`font-manrope text-xl font-semibold text-gray-2700 tracking-tightest  group-hover:text-purple-1100`}
        >
          {data?.title?.text}
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: data?.description?.html,
        }}
        className="text-base font-normal text-gray-600 group-hover:text-gray-2500 font-inter tracking-tight_1 link_style"
      />
    </div>
  );
};

export default FeatureDaliCard;
