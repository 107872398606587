import React from "react";
import "../../../styles/components/Slices/TestimonialMonet.scss";

import TestimonialMonetCard from "../../Pieces/TestimonialMonetCard";

const TestimonialMonet = ({ slice,location }) => {
  return (
    <>
      <article
        className={`relative overflow-hidden ${location?.pathname !== "/pricing/"? "py-10 lg:py-20":"pb-10"} ${slice?.primary?.theme === "Dark" ? "bg-[#250038] monet_bg  my-10 lg:my-20 " : "bg-[#FAFAFA]"}`}
      >
        <TestimonialMonetCard data={slice} />
      </article>
    </>
  );
};

export default TestimonialMonet;
