import React, { useContext, useRef, useState, useEffect } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "../../../styles/components/Slices/SectionHopper.scss";
import { Chevron2 } from "../../../assets/icons";
import VideoComponent from "../../VideoComponent";
import posterVideo from "../../../assets/videoPoster.png";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";

const Video = ({ src, posterImage }) => {
  return (
    <VideoComponent
      poster={posterImage || posterVideo}
      data-aos="fade"
      data-aos-delay="150"
      key={src}
      className="object-cover"
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};

const SectionHopper = ({ slice }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const lastItemRef = useRef(null);
  const [isLastVisible, setIsLastVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );
  const isDesktop = useMediaQuery({ minWidth: 1060 });
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const { toggleModal } = useContext(ModalContext);

  useEffect(() => {
    if (lastItemRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => setIsLastVisible(entry.isIntersecting),
        { root: containerRef.current, threshold: 1.0 }
      );
      observer.observe(lastItemRef.current);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <section
      className={`bg-gray-2800 py-8 ${topSpacing === "80" && "sm:pt-20"} ${topSpacing === "40" && "sm:pt-10"} ${bottomSpacing === "80" && "sm:pb-20"} ${bottomSpacing === "40" && "sm:pb-10"}`}
    >
      <section className="max-w-6xl px-4 mx-auto lg:px-6 xl:px-0 sm:px-12">
        {emptyHeading && (
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            descriptionStyle="max-w-[890px]"
            textAreaStyle="!mt-4"
            sectionStyle="lg:!pb-10"
          />
        )}
        <section
          ref={containerRef}
          className={`gap-4 sm:gap-5 ${isLastVisible ? "" : "hopper_card_slide_opacity"} ${isDesktop ? "grid grid-cols-5" : "flex overflow-hidden overflow-x-auto scroll-smooth no-scrollbar"}`}
        >
          {slice?.items?.map((value, index) => {
            const isLastItem = index === slice.items.length - 1;
            return value?.card_link?.url ? (
              <a
                href={value?.card_link?.url}
                className={`cursor-pointer group relative  block p-1.5  ${!value?.video_link?.url && !value?.card_image?.url ? "min-w-[214px]" : ""}`}
                key={index}
                ref={isLastItem ? lastItemRef : null}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <AnimatePresence>
                  {hoveredIndex === index && (
                    <motion.span
                      className="absolute inset-0 h-full w-full bg-[#EFECFF] dark:bg-slate-800/[0.8] block rounded-lg z-0"
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                        filter: 'blur(2px)',
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.15, delay: 0.2 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <div className="z-20 relative hopper_border">
                  {(value?.video_link?.url || value?.card_image?.url) && (
                    <div
                      className={`max-w-[240px] min-h-[140px] max-h-[140px] flex justify-center ${isDesktop ? "min-w-full" : "min-w-[240px]"} rounded-t-md bg-gray-1500 cursor-pointer`}
                    >
                      {value?.video_link?.url ? (
                        <Video
                          ref={videoRef}
                          src={value?.video_link?.url}
                          posterImage={posterVideo}
                        />
                      ) : (
                        <GatsbyImage
                          className="rounded-t-md object-cover"
                          image={getImage(value?.card_image)}
                        />
                      )}
                    </div>
                  )}

                  <div
                    className={`bg-white ${value?.video_link?.url && value?.card_image?.url ? "rounded-b-md" : "rounded-md"} flex justify-between items-center py-3 px-3.5 `}
                  >
                    <p className="text-lg text-gray-2700 font-manrope group-hover:text-purple-1100 font-semibold tracking-[-0.45px]">
                      {value?.card_title?.text}
                    </p>
                    <div className="transition-transform duration-700 group-hover:translate-x-2">
                      <Chevron2 />
                    </div>
                  </div>
                </div>
              </a>
            ) : (
              <div
                className={` group relative  block p-1.5  ${!value?.video_link?.url && !value?.card_image?.url ? "min-w-[214px]" : ""}`}
                key={index}
                ref={isLastItem ? lastItemRef : null}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <AnimatePresence>
                  {hoveredIndex === index && (
                    <motion.span
                      className="absolute inset-0 h-full w-full bg-[#EFECFF] dark:bg-slate-800/[0.8] block rounded-lg z-0"
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                        filter: 'blur(2px)',
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.15, delay: 0.2 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <div className="z-20 relative hopper_border">
                  {(value?.video_link?.url || value?.card_image?.url) && (
                    <div
                      className={`max-w-[240px] min-h-[140px]  max-h-[140px] ${isDesktop ? "min-w-full" : "min-w-[240px]"} flex justify-center rounded-t-md bg-gray-1500`}
                    >
                      {value?.video_link?.url ? (
                        <Video
                          ref={videoRef}
                          src={value?.video_link?.url}
                          posterImage={posterVideo}
                        />
                      ) : (
                        <GatsbyImage
                          className="rounded-t-md"
                          image={getImage(value?.card_image)}
                        />
                      )}
                    </div>
                  )}

                  <div
                    className={`bg-white ${value?.video_link?.url && value?.card_image?.url ? "rounded-b-md" : "rounded-md"} flex justify-between items-center py-3 px-3.5 `}
                  >
                    <p className="text-lg text-gray-2700 font-manrope group-hover:text-purple-1100 font-semibold tracking-[-0.45px]">
                      {value?.card_title?.text}
                    </p>
                    <div className="transition-transform duration-700 group-hover:translate-x-2">
                      <Chevron2 />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </section>
    </section>
  );
};

export default SectionHopper;
