import React from "react";

export const CustomerIcon = ({stroke}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M11.2498 14.346C11.8895 14.5317 12.5524 14.6257 13.2185 14.625C14.2898 14.6265 15.3472 14.3823 16.3093 13.911C16.3377 13.2388 16.1462 12.5756 15.7639 12.022C15.3815 11.4684 14.8291 11.0545 14.1903 10.8431C13.5515 10.6317 12.8613 10.6343 12.2241 10.8505C11.587 11.0668 11.0377 11.4848 10.6595 12.0413M11.2498 14.346V14.3438C11.2498 13.509 11.0353 12.7238 10.6595 12.0413M11.2498 14.346V14.4255C9.80638 15.2948 8.15273 15.7528 6.46777 15.75C4.71952 15.75 3.08377 15.2662 1.68727 14.4255L1.68652 14.3438C1.68595 13.2821 2.03873 12.2504 2.68926 11.4114C3.33979 10.5724 4.25105 9.97382 5.27939 9.70995C6.30773 9.44607 7.39464 9.53196 8.36877 9.95407C9.3429 10.3762 10.1488 11.1105 10.6595 12.0413M8.99977 4.78125C8.99977 5.45258 8.73309 6.09641 8.25839 6.57111C7.78369 7.04582 7.13985 7.3125 6.46852 7.3125C5.79719 7.3125 5.15336 7.04582 4.67866 6.57111C4.20396 6.09641 3.93727 5.45258 3.93727 4.78125C3.93727 4.10992 4.20396 3.46609 4.67866 2.99139C5.15336 2.51668 5.79719 2.25 6.46852 2.25C7.13985 2.25 7.78369 2.51668 8.25839 2.99139C8.73309 3.46609 8.99977 4.10992 8.99977 4.78125ZM15.1873 6.46875C15.1873 6.99089 14.9799 7.49165 14.6106 7.86087C14.2414 8.23008 13.7407 8.4375 13.2185 8.4375C12.6964 8.4375 12.1956 8.23008 11.8264 7.86087C11.4572 7.49165 11.2498 6.99089 11.2498 6.46875C11.2498 5.94661 11.4572 5.44585 11.8264 5.07663C12.1956 4.70742 12.6964 4.5 13.2185 4.5C13.7407 4.5 14.2414 4.70742 14.6106 5.07663C14.9799 5.44585 15.1873 5.94661 15.1873 6.46875Z"
      stroke={stroke}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
