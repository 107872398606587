import React from "react";
export const SVGDot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    className="w-3 sm:w-4"
  >
    <circle cx="8.99766" cy="8.59922" r="8.4" fill="white" fillOpacity="0.3" />
  </svg>
);
