import React from "react";

const LeftToRightTabletArrow = ({ styleClass }) => {
  return (
    <svg
      width="73"
      height="6"
      viewBox="0 0 73 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${styleClass}`}
    >
      <path d="M73 3L68 0.113249V5.88675L73 3Z" fill="#5E44FF" />

      <path
        class="moving_dots_left_to_right"
        d="M0 3.1H67.2"
        stroke="url(#paint0_linear_211_12793)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeftToRightTabletArrow;
