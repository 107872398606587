import React from "react";

export const PricingIcon = ({stroke}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 4.5V13.5M6.75 11.3865L7.40925 11.8807C8.2875 12.54 9.71175 12.54 10.5908 11.8807C11.4698 11.2215 11.4698 10.1535 10.5908 9.49425C10.152 9.16425 9.576 9 9 9C8.45625 9 7.9125 8.835 7.49775 8.50575C6.66825 7.8465 6.66825 6.7785 7.49775 6.11925C8.32725 5.46 9.67275 5.46 10.5023 6.11925L10.8135 6.36675M15.75 9C15.75 9.88642 15.5754 10.7642 15.2362 11.5831C14.897 12.4021 14.3998 13.1462 13.773 13.773C13.1462 14.3998 12.4021 14.897 11.5831 15.2362C10.7642 15.5754 9.88642 15.75 9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 7.20979 2.96116 5.4929 4.22703 4.22703C5.4929 2.96116 7.20979 2.25 9 2.25C10.7902 2.25 12.5071 2.96116 13.773 4.22703C15.0388 5.4929 15.75 7.20979 15.75 9Z"
      stroke={stroke}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
