import React from "react";
import "../../../styles/components/Slices/SectionWarhol.scss";

const SectionWarhol = ({ slice }) => {
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="py-10 lg:py-20 lg:mx-11">
        <div className="flex flex-col gap-10">
          <div
            className={`${slice?.primary?.heading_section_position === "center" && "self-center"}`}
          >
            <p
              className={`inline text-base font-medium  uppercase font-inter tracking-widest_1 text-purple-1500  ${(slice?.primary?.caption_title_style || slice?.caption_title_dropdown) === "rounded rectangle" && "bg_text"}`}
            >
              {slice?.primary?.caption_title?.text}
            </p>
          </div>
          <div
            className={`text-gray-600 text-base lg:max-w-[960px] sm:text-lg font-inter tracking-tightest font-normal content_style ${slice?.primary?.content_section_position && "text-center self-center"}`}
            dangerouslySetInnerHTML={{
              __html: slice?.primary?.content?.html,
            }}
          />
        </div>
      </article>
    </section>
  );
};

export default SectionWarhol;
