import React from "react";

export const ArrowDown = ({fill}) => {
  const colorVariants = {
    gray:"stroke-gray-2500 fill-gray-2500",
    grayHover:"stroke-gray-2700 fill-gray-2700",
    white:"stroke-white fill-white"
  };
  return(
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
    className={`${colorVariants[fill]}`}
  >
    <path d="M3.48479 4.10154L2.42796 4.10154C2.33635 4.10154 2.28577 4.20682 2.34182 4.27791L7.15569 10.4412C7.19641 10.4936 7.24856 10.5359 7.30815 10.5651C7.36774 10.5942 7.4332 10.6094 7.49954 10.6094C7.56587 10.6094 7.63133 10.5942 7.69093 10.5651C7.75052 10.5359 7.80266 10.4936 7.84338 10.4412L12.6573 4.27791C12.7133 4.20545 12.6627 4.10154 12.5711 4.10154L11.5143 4.10154C11.4473 4.10154 11.383 4.13299 11.342 4.18494L7.50022 9.10682L3.65706 4.18494C3.61604 4.13299 3.55178 4.10154 3.48479 4.10154Z" fill="#34344B" fill-opacity="0.7"/>

  </svg>
)};
