import React from "react";
const RightToLeftGradiantArrow = () => {
  return (
    <svg
      width="97"
      height="40"
      viewBox="0 0 97 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden lg:block xl:-mr-1"
    >
      <path
        d="M96.4375 20L91.4375 17.1132V22.8868L96.4375 20Z"
        fill="#5E44FF"
      />
      <path
      class="moving_dots_left_to_right"
        d="M0.9375 20.1H91.9375"
        stroke="url(#paint0_linear_211_12133)"
        strokeWidth="1"
        strokeDasharray="4 2"
      />

      <defs>
        <linearGradient
          id="paint0_linear_211_12133"
          x1="0.9375"
          y1="20.5"
          x2="96.4375"
          y2="20.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#BFBFBF" />
          <stop offset="1" stopColor="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default RightToLeftGradiantArrow;
