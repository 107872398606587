/**
 * The table component that return html string of table component
 * @param {Object} Blog Blog card
 * @returns String to render in html
 * @author Wajeeh Aslam
 */
const BlogDetailTable = ({ blog }) => {
  return `
      <h6 class="text-xl font-bold my-2">
        ${blog?.data?.body[0]?.primary?.table_title?.text}
      </h6>
      <table class="max-w-full w-full block border-collapse overflow-x-auto whitespace-nowrap text-center">
        <thead>
        ${(
          blog?.data?.body &&
          blog?.data?.body[0]?.items?.map((x, i) => {
            if (i === 0) {
              return `
                  <tr>
                    ${Object.values(x)
                      ?.filter((v) => v.text)
                      ?.map((_x) => {
                        return `<th
                          scope="col"
                          class="text-base font-semibold text-gray-900 px-6 py-4 border border-gray-2100"
                        >
                          ${_x.text}
                          </th>`;
                      })
                      ?.join("")}
                  </tr>
                `;
            }
          })
        )?.join("")}
        </thead>
        <tbody>
        ${(
          blog?.data?.body &&
          blog?.data?.body[0]?.items?.map((x, i) => {
            if (i !== 0) {
              return `
              <tr>
                ${Object.values(x)
                  ?.filter((v) => v.text)
                  ?.map((_x) => {
                    return `<td class="blog-table-content text-lg break-words text-gray-2100 whitespace-[initial] font-light px-6 py-4 border border-gray-2100">
                      ${_x.html}
                    </td>`;
                  })
                  .join("")}
              </tr>`;
            }
          })
        )?.join("")}
        </tbody>
      </table>`;
};

export default BlogDetailTable;
