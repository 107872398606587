import React from "react";
import VideoComponent from "../../VideoComponent";
import posterVideo from "../../../assets/videoPoster.png";

const SlicesVideoComponent = ({ src, posterImage, videoComponentClass }) => {
  return (
    <VideoComponent
      poster={posterImage || posterVideo}
      data-aos="fade"
      data-aos-delay="150"
      key={src}
      className={`h-full ${videoComponentClass}`}
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};

export default SlicesVideoComponent;
