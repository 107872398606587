import React from "react";
import NewAccordion from "../../utilities/NewAccordion";

const FAQ = ({ heading, items,darkClass }) => {
  return (
    <div className={`flex flex-col gap-8 lg:flex-row lg:gap-9 ${darkClass}`}>
      <div className="min-w-[275px] dark:text-white text-purple-1100 sm:leading-10 tracking-tighter_1 text-2.5xl sm:text-3.25xl text-center lg:text-left font-bold sm:font-semibold">
        {heading}
      </div>

      <ul className="list_gap">
        {items?.map((item, index) => (
          <NewAccordion key={`anwser_${index}`} title={item?.question?.text}>
            {item?.answer?.html ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.answer?.html,
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.answer?.text,
                }}
              />
            )}
          </NewAccordion>
        ))}
        <span className="block border-t border-gray-200" aria-hidden="true" />
      </ul>
    </div>
  );
};

export default FAQ;
