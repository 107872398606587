import { Disclosure, Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { DownIcon } from "../assets/icons";
import { useHeadsObserver } from "../hooks/useHeadsObserver";
import "../styles/additional-styles/catalog.scss";

const CatalogList = ({ list }) => {
  const { activeId, setActiveId } = useHeadsObserver();
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      setActiveId(id);
    }, 1000);
  };

  return (
    <>
      {list?.map((list) => (
        <li
          key={list.id}
          className={`catalog-content cursor-pointer ${
            activeId === list.id && "catalog-content-active"
          } md:py-1.5  text-gray-1200 lg:hover:text-purple-1500 flex items-center`}
          onClick={() => scrollTo(list.id)}
        >
          <h2
            className="text-sm font-medium tracking-tighter_3"
            dangerouslySetInnerHTML={{ __html: list.text }}
          />
        </li>
      ))}
    </>
  );
};

/**
 * @summary This component renders the Catalog on the
 * left hand side in the blog detail page.
 * @param {Object} catalog object returned by stringManipulation();
 * @returns JSX.Element
 */
const Catalog = ({ catalogContent }) => {
  return (
    <>
      {!!catalogContent?.length && (
        <>
          <section className="hidden pt-10 lg:block ">
            <div>
              <p className="text-lg font-semibold text-gray-2700 leading-7 tracking-[-0.9] font-manrope">
                Contents
              </p>

              <section className="max-h-[562px] overflow-auto">
                <ul className="flex flex-col gap-2.5 mt-6 catalog-content_container ">
                  <CatalogList list={catalogContent} />
                </ul>
              </section>
            </div>
          </section>

          <Popover className="relative">
            <Popover.Button className="py-2.5 my-5 flex items-center rounded-full w-full gap-1.5 justify-center lg:hidden sm:text-lg leading-7 bg-white text-gray-2500 font-manrope border border-solid border-gray-2200 font-semibold focus:outline-purple-1500">
              <span>Contents</span> <DownIcon />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-30 -mt-3.5 w-screen max-w-[341px] px-2 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div
                  className="overflow-hidden bg-white border border-gray-200 rounded-lg ring-1 ring-black ring-opacity-5"
                  style={{
                    boxShadow:
                      "0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <ul className=" left_border_none">
                    <CatalogList list={catalogContent} />
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </>
      )}
    </>
  );
};

export default Catalog;
