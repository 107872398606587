import React, { useMemo } from "react";
import { _slugify } from "../../../utilities/helpers";

const tagsStyles = {
  "computer-vision": "bg-purple-350/[0.13] text-purple-250",
  "machine-learning": "bg-yellow-1100/[0.13] text-yellow-1200",
  product: "bg-green-550/[0.13] text-green-650",
  company: "bg-gray-1500 text-gray-1200",
  "data-operations": "bg-blue-250/[0.13] text-blue-250",
  "data-quality": "bg-red-200/[0.13] text-red-200",
  newsletter: "bg-purple-150/[0.13] text-purple-150",
};

const tagsVariants = {
  sm: "px-2.5 py-1 text-xs tracking-[0.3px]",
  lg: "px-2.5 py-1.5 text-sm tracking-[1.4px]",
};

const findKey = (text) => {
  const matchingKeys = Object.keys(tagsStyles).filter((key) => text === key);
  if (matchingKeys.length > 0) {
    return matchingKeys[0];
  } else {
    const keys = Object.keys(tagsStyles);
    return keys[3];
  }
};

const Tag = ({ text, size = "sm" }) => {
  const tagStyle = useMemo(
    () => tagsStyles[findKey(_slugify(text).toLowerCase())],
    [text]
  );

  return (
    <section
      className={`${tagStyle} ${tagsVariants[size]} rounded-[20px] font-semibold uppercase font-manrope`}
    >
      {text}
    </section>
  );
};

export default Tag;
