
export const addAltToImg = (html) => {
  return html.replace(/<img\b(?![^>]*\balt=)[^>]*>/g, (imgTag, index) => {
    // Check if the img tag already has an alt attribute
    if (!imgTag.includes("alt=")) {
      // Add the alt attribute before the closing '>'
      return imgTag.replace(/\/?>/, ` alt="blog_image_${index}"$&`);
    }
    return imgTag;
  });
};
