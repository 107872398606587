import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import lightBgOlips from "../../../assets/backgrounds/blogOllips.png";
import {
  default as darkVector,
  default as darkVectorMobile,
  default as darkVectorTablet,
} from "../../../assets/backgrounds/index-vector.png";
import "../../../styles/components/Pieces/HomePageQuizForm.scss";
import { WORK_EMAIL_VALIDATION } from "../../../utilities/helpers";

const validationSchema = Yup.object({
  email: WORK_EMAIL_VALIDATION,
});

const BlogPageQuizForm = ({ setIsFormFilled, quizFormdata }) => {
  useEffect(() => {
    const storedEmail = localStorage.getItem("Blog Quiz email");
    if (storedEmail) {
      setIsFormFilled(true);
    }
  }, [setIsFormFilled]);

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem("Blog Quiz email", values.email);
    setIsFormFilled(true);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="flex items-center justify-center">
          <section className="overflow-hidden relative  flex flex-col max-w-6xl mx-auto gap-5 sm:gap-10 py-10 sm:py-[129.5px]  px-5 sm:px-8  form-bg rounded-lg shadow-lg">
            <section className="flex flex-col">
              <div className="flex flex-col gap-5 mx-auto text-center">
                <p className="uppercase font-manrope !text-xs !text-[#9C8CFF] -tracking-tightest_5 !font-semibold !my-0">
                  {quizFormdata?.primary?.caption_title?.text}
                </p>
                <p className="!text-2.5xl md:!text-4.5xl !font-bold !my-0 !tracking-tightest_4 !text-white max-w-[672px]">
                  {quizFormdata?.primary?.title?.text}
                </p>
                <p className="!text-base sm:!text-xl !font-normal !text-[#D0C8FF] !tracking-[-1px] !font-inter !my-0">
                  {quizFormdata?.primary?.description?.text}
                </p>
              </div>
              <>
                <img
                  src={darkVector}
                  loading="eager"
                  alt="stats"
                  className="absolute left-0 z-0 hidden bottom-[-1px] lg:block"
                />
                <img
                  src={darkVectorTablet}
                  loading="eager"
                  alt="stats"
                  className="absolute left-0 z-0 hidden bottom-32 sm:block lg:hidden"
                />
                <img
                  src={darkVectorMobile}
                  loading="eager"
                  alt="stats"
                  className="absolute left-0 z-0 block bottom-32 sm:hidden"
                />
              </>
              <img
                src={lightBgOlips}
                loading="eager"
                alt="BG Olips"
                className="absolute left-0 z-0 hidden  lg:block lg:bottom-[-1px]"
              />
              <div className="flex flex-col items-center justify-center mt-5">
                <Form
                  className="w-full relative gap-3 mb-4 max-w-[513px]"
                  onSubmit={handleSubmit}
                >
                  <div className="cta_border_animation !rounded-4xl p-[1px]">
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter your email address"
                      className="w-full border-none placeholder:text-sm text-gray-2500 placeholder-gray-600 focus:rounded-4xl rounded-4xl focus:!shadow-none focus:!outline-none focus:ring-0 focus:!border-none sm:placeholder:text-base placeholder:tracking-[-0.8px] leading-6 disabled:bg-gray-300 bg-white pl-[22px] pr-20 sm:pr-32 py-3.5 ring-0"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="absolute right-1.5 top-1/2 -translate-y-1/2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`disabled:cursor-not-allowed flex gap-1 items-center px-4 py-2 w-full font-manrope tracking-[-0.4px] text-white ${!isSubmitting ? "itk-btn_dark hover:bg-[#5e24ff]" : "bg-purple-300"} shrink-0 rounded-4xl font-semibold`}
                    >
                      <span className="hidden sm:inline-block">
                        Take the quiz now
                      </span>
                      <svg
                        className="block sm:hidden"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.3672 11.4328L11.5125 3.74766C11.4445 3.68906 11.3578 3.65625 11.2664 3.65625H9.19219C9.01875 3.65625 8.93906 3.87187 9.07031 3.98438L17.2781 11.1094H3.5625C3.45937 11.1094 3.375 11.1937 3.375 11.2969V12.7031C3.375 12.8062 3.45937 12.8906 3.5625 12.8906H17.2758L9.06797 20.0156C8.93672 20.1305 9.01641 20.3438 9.18984 20.3438H11.3344C11.3789 20.3438 11.4234 20.3273 11.4562 20.2969L20.3672 12.5672C20.4483 12.4966 20.5134 12.4095 20.558 12.3116C20.6025 12.2138 20.6256 12.1075 20.6256 12C20.6256 11.8925 20.6025 11.7862 20.558 11.6884C20.5134 11.5905 20.4483 11.5034 20.3672 11.4328Z"
                          fill="white"
                        />
                      </svg>
                      {isSubmitting && (
                        <svg
                          className="w-5 h-5 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                    </button>
                  </div>
                </Form>
                {errors.email && touched.email ? (
                  <div className="z-10 flex text-red-500">{errors.email}</div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      )}
    </Formik>
  );
};

export default BlogPageQuizForm;
