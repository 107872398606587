import React from "react";
import "../../../styles/components/logoMarquee.scss";
import { LOGOS_DURATION } from "../../../constants";

export const LogoMarquee = ({
  logoArray,
  logoBackground = "dark:bg-gray-2600",
  duration,
}) => {
  return (
    <div className="tag-list">
      <InfiniteLoopSlider duration={duration || LOGOS_DURATION}>
        {logoArray?.items.map((item, index) => (
          <Logo
            src={item?.brand_image?.url ? item?.logo?.url : item?.logo?.url}
            alt={item?.logo?.alt || ""}
            key={index}
            logoBackground={logoBackground}
            dimensions={item?.brand_image?.dimensions}
          />
        ))}
      </InfiniteLoopSlider>

      <div className="fade" />
    </div>
  );
};

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const Logo = ({ src, alt, logoBackground, dimensions }) => (
  <div className={`tag ${logoBackground}`}>
    <img
      className="object-scale-down "
      src={src}
      alt={alt || "Logo"}
      width={dimensions?.width}
      height={dimensions?.height}
    />
  </div>
);

const LogosDali = ({ slice,location }) => {
  const dark = slice?.primary?.theme == "dark";

  return (
    <section className={dark && "dark_slice_background"}>
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <article
          className={` lg:mx-11 ${location?.pathname !== "/pricing/" && "pt-10 pb-0 "}`}
        >
          <div className="img_grayscale_opacity">
            <p
              className={`text-center uppercase ${dark ? "text-white" : "text-gray-1300"} sm:text-sm text-2xs tracking-[0.07em]`}
            >
              {slice?.primary?.caption_title?.text}
            </p>
          </div>

          <LogoMarquee logoArray={slice} />
        </article>
      </section>
    </section>
  );
};

export default LogosDali;
