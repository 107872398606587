import React from "react";
import CTAPicassolCard from "../../Pieces/CTAPicassolCard";

const CTAPicasso = ({ slice }) => {
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="relative py-10 lg:py-20 lg:mx-11 ">
        <div className={` `}>
          <section className="flex justify-start">
            <p className=" text-purple-1100 pb-5 sm:pb-10 text-2.5xl font-manrope text-left tracking-tighter_3 font-semibold sm:tracking-[-1.8px] ">
              {slice?.primary.title.text}
            </p>
          </section>

          <div className="relative flex flex-col flex-1 gap-5">
            <CTAPicassolCard data={slice} />
          </div>
        </div>
      </article>
    </section>
  );
};

export default CTAPicasso;
