import React, { useRef } from "react";
import posterVideo from "../../../assets/videoPoster.png";
import VideoComponent from "../../VideoComponent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Video = ({ src, posterImage }) => {
  return (
    <VideoComponent
      poster={posterImage || posterVideo}
      data-aos="fade"
      data-aos-delay="150"
      key={src}
      className="h-full"
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};

const CarouselMonetCard = ({ cardData, key }) => {
  const videoRef = useRef(null);
  return (
    <div className="flex flex-col w-full sm:max-w-[537px] gap-5 overflow-hidden">
      {cardData?.video_link?.url ? (
        <div className="justify-center lg:justify-self-start flex w-full sm:max-w-[537px] h-[315px] rounded-[4px]">
          <Video
            ref={videoRef}
            src={cardData?.video_link?.url}
            posterImage={posterVideo}
          />
        </div>
      ) : (
        cardData?.card_asset?.url && (
          <GatsbyImage
            image={getImage(cardData?.card_asset)}
            alt={cardData?.card_asset?.alt || "Carousel Monet Card Asset"}
            className=" w-full max-w-[537px] h-[315px] rounded-[4px]"
          />
        )
      )}
      <div className="flex flex-col items-start gap-5">
        <p className="text-lg font-semibold text-purple-1100 sm:text-xl font-manrope tracking-tighter_1">
          {cardData?.card_title?.text}
        </p>
        <p className="text-base font-normal text-left text-gray-2500 font-inter tracking-tight_1">
          {cardData?.card_description?.text}
        </p>
      </div>
    </div>
  );
};

export default CarouselMonetCard;
