import React from "react";

export const BookDemoIcon = () => {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.4375 36.2709V16.875C58.4375 15.4053 57.8536 13.9958 56.8144 12.9565C55.7751 11.9172 54.3656 11.3334 52.8958 11.3334H14.1042C12.6344 11.3334 11.2249 11.9172 10.1856 12.9565C9.14635 13.9958 8.5625 15.4053 8.5625 16.875V55.6667C8.5625 57.1364 9.14635 58.546 10.1856 59.5853C11.2249 60.6245 12.6344 61.2084 14.1042 61.2084H36.2708"
        stroke="white"
        stroke-width="5.54167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.584 5.79163V16.875"
        stroke="white"
        stroke-width="5.54167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.416 5.79163V16.875"
        stroke="white"
        stroke-width="5.54167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5625 27.9584H58.4375"
        stroke="white"
        stroke-width="5.54167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.8965 44.5834V61.2084"
        stroke="white"
        stroke-width="5.54167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.584 52.8959H61.209"
        stroke="white"
        stroke-width="5.54167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
