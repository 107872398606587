import AOS from "aos";
import "./src/styles/global.scss";
import { focusHandling } from "cruip-js-toolkit";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import * as React from 'react'
import { PrismicPreviewProvider,componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import linkResolver from "./linkResolver";
import HomePage from "./src/pages/index";
import BlogDetail from "./src/templates/blog_detail"

export const wrapRootElement = ({ element }) => {
  AOS.init({
    once: true,
    disable: "phone",
    duration: 2000,
    easing: "ease-out-cubic",
  });

  document.querySelector("html").style.scrollBehavior = "auto";
  window.scroll({ top: 0 });
  document.querySelector("html").style.scrollBehavior = "";
  focusHandling("outline");

  // Calls conversion event for gtag
  if (window.gtag) {
    window.gtag("event", "conversion", {
      send_to: "AW-443101053/jdvMCLaqqfIBEP3epNMB",
    });
  }

  if (process.env.NODE_ENV === "production") {
    disableReactDevTools();
  }

  return <PrismicPreviewProvider repositoryConfigs={[
    {
      repositoryName: "encord",
      linkResolver:linkResolver,
    },
  ]}>{element}</PrismicPreviewProvider>;
};