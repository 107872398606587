import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { navigate, Script } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { blockedDomains, emailRegex, HUBSPOT_PROXY_URL } from "../constants";
import { isDomainBlocked } from "../utilities/helpers";

const buttonSize = {
  sm: { button: "p-3 text-xs w-160", button: "btn-md text-sm" },
  md: { input: "py-2 text-sm", button: "btn-md text-sm" },
  lg: { input: "py-3 text-lg", button: "btn" },
  xl: { input: "py-3 text-lg", button: "btn-md sm:text-lg" },
};

const LeadFormWithAPI = ({
  pageName = "",
  location,
  portalId = "25381551",
  formId = "c90a5ae1-1852-43f5-9346-a57837b9f470",
  btnText = "Get Free Trial",
  size = "lg",
  allowAllEmails = false,
  hideRevenueHero,
  isCustomBtnBg = false,
  btnColor,
  btnStyling,
  inputStyle,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!error) {
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      setError("");
      try {
        if (emailRegex.test(email) && !isDomainBlocked(email)) {
          setLoading(true);

          /**
         @summary Block verify api for temporary time
          const response = await axios.get(
          `https://verifymail.io/api/${email}?key=${VERIFY_EMAIL_API_KEY}`
        );
         */

          if (
            // response?.data?.deliverable_email &&
            // !response?.data?.catch_all &&
            // response?.data?.mx &&
            // !response?.data?.disposable
            true
          ) {
            const requestPayload = {
              fields: [
                {
                  objectTypeId: "0-1",
                  name: "email",
                  value: email,
                },
              ],
            };
            if (hubspotutk) {
              Object.assign(requestPayload, {
                context: {
                  hutk: hubspotutk,
                  pageUri: location?.href,
                  pageName: pageName,
                },
              });
            }
            const res = await axios.post(
              HUBSPOT_PROXY_URL,
              Object.assign(requestPayload, {
                portalId,
                formId,
              })
            );
            /**
             * Append custom properties to Heap
             */
            if (window.heap) {
              window.heap.addUserProperties({
                marketing_email: email,
              });
            }

            if (
              location?.pathname !== "/lp/rsna-2023/" &&
              !hideRevenueHero &&
              window.hero &&
              btnText !== "Subscribe"
            ) {
              const _res = await window.hero.submit({
                email: email,
              });
              await window.hero.dialog.open(_res);
            }

            if (hideRevenueHero) {
              setIsOpen(true);
            }

            // if (res?.data?.redirectUri) {
            //   navigate(res?.data?.redirectUri);
            // }
            if (btnText === "Subscribe") {
              navigate("/subscribe");
            }
            if (res?.data?.inlineMessage) {
              toast.success(
                <div
                  dangerouslySetInnerHTML={{
                    __html: res?.data?.inlineMessage,
                  }}
                />
              );
            }
          } else {
            setError("Please enter your work e-mail.");
          }
        } else {
          setError("Email must be formatted correctly.");
        }
      } catch (error) {
        if (
          error?.response?.data &&
          error?.response?.data.errors &&
          error?.response?.data.errors[0]?.message
        ) {
          const [, errorMessage] =
            error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
          if (errorMessage) {
            setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
          }
        } else {
          setError("Please enter your work e-mail.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div>
        {hideRevenueHero && (
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => {
                setIsOpen(false);
              }}
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <div
                    className="meetings-iframe-container w-[850px] bg-white p-10 z-50 relative"
                    data-src="https://meetings-eu1.hubspot.com/eric-landau/cvpr-calendar?embed=true"
                  >
                    <div className="absolute w-4 h-4 mt-4 top-1 right-7">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-black border border-transparent focus:outline-none focus-visible:ring-0"
                        onClick={() => setIsOpen(false)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <Script
                    type="text/javascript"
                    src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                  ></Script>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}

        <form
          className="relative flex flex-col flex-wrap gap-3 mb-4 commercial-form sm:flex-row"
          onSubmit={handleSubmit}
        >
          <div className={`w-full md:w-[418px] min-h-[auto]`}>
            <legend
              className="hs-field-desc"
              style={{ display: "none" }}
            ></legend>
            <div className="">
              <input
                name="email"
                placeholder="Your work email"
                type="email"
                className={`${inputStyle} w-full ${buttonSize[size]?.input} placeholder-gray-1200 dark:placeholder:text-white dark:text-white rounded-4xl leading-6 border border-transparent disabled:bg-gray-300 text-gray-1200 bg-gray-50 px-4 focus:outline-none focus:ring-1 focus:ring-purple-500 shadow-lg`}
                required
                disabled={loading}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setError("");
                  if (!allowAllEmails) {
                    const _filtered = blockedDomains.filter((x) =>
                      event.target.value.includes(x)
                    );
                    if (_filtered?.length) {
                      setError("Please enter your work email.");
                    }
                  }
                }}
                value={email}
              />
            </div>
            {error && (
              <ul className="pt-1 text-left text-red-500" role="alert">
                <li>
                  <label>{error}</label>
                </li>
              </ul>
            )}
          </div>
          <div
            className={`relative w-full md:w-auto md:absolute md:right-0 ${btnStyling}`}
          >
            <div className="hs-field-desc" style={{ display: "none" }}></div>
            <div className="actions">
              <button
                type="submit"
                disabled={loading || !!error}
                className={`hs-button ${
                  buttonSize[size]?.button
                } w-full text-white ${
                  isCustomBtnBg ? btnColor : "bg-[#4F33CA]"
                } hover:bg-purple-400 shrink-0 disabled:bg-purple-400 rounded-full font-semibold border border-[#4F33CA]`}
              >
                {loading ? (
                  <div className="flex items-center">
                    Submitting
                    <div className="w-4 h-4 ml-2 border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                  </div>
                ) : (
                  <>{btnText}</>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const FormPlaceholder = ({ btnText, size = "lg" }) => {
  return (
    <div>
      <form className="relative flex flex-col flex-wrap gap-3 mb-4 sm:flex-row">
        <div className="w-full md:w-[512px] min-h-[auto]">
          <div>
            <input
              name="email"
              placeholder="Your work email"
              type="email"
              className={`undefined w-full py-3 text-lg placeholder-gray-1200 dark:placeholder:text-white dark:text-white rounded-4xl leading-6 border border-transparent disabled:bg-gray-300 text-gray-1200 bg-gray-50 px-4 focus:outline-none focus:ring-1 focus:ring-purple-500 shadow-lg`}
              required
              disabled
            />
          </div>
        </div>
        <div className="relative w-full md:w-auto md:absolute md:right-0">
          <div className="hs-field-desc" style={{ display: "none" }}></div>
          <div className="actions">
            <button
              type="submit"
              disabled
              className={`hs-button ${buttonSize[size]?.button} w-full text-white customer_btn_bg border-none h-[50px] font-manrope tracking-tighter_3 hover:bg-purple-400 shrink-0 disabled:bg-purple-400 rounded-full font-semibold border border-[#4F33CA]`}
            >
              <>{btnText}</>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const NewHomeLeadFormWithAPIForm = ({
  btnText,
  formId,
  hideRevenueHero = false,
  isCustomBtnBg = false,
  btnColor,
  size,
  btnStyling,
  allowAllEmails = false,
  routerId = "231",
  inputStyle,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      // eslint-disable-next-line no-undef
      window.hero = new RevenueHero({ routerId });
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://app.revenuehero.io/scheduler.min.js";
    script.onload = () => {
      setScriptLoaded(true);
      handleLoad();
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      handleLoad();
    };
  }, [routerId]);

  if (!scriptLoaded) {
    return <FormPlaceholder btnText={btnText} size={size} />;
  }

  return (
    <>
      <LeadFormWithAPI
        btnText={btnText}
        formId={formId}
        hideRevenueHero={hideRevenueHero}
        btnColor={btnColor}
        allowAllEmails={allowAllEmails}
        location={location}
        isCustomBtnBg={isCustomBtnBg}
        size={size}
        inputStyle={inputStyle}
        btnStyling={btnStyling}
      />
    </>
  );
};

export default NewHomeLeadFormWithAPIForm;
