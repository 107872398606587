import React from "react";
import InteractionCard from "../../Pieces/InteractionCard";

const FeatureDegard = ({ slice }) => {
  const { items = [] } = slice;
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="lg:mx-11">
        <section className="grid grid-cols-2 pb-10 lg:pb-20 lg:grid-cols-3 gap-x-3 sm:gap-x-6 gap-y-10">
          {items?.map((data, index) => (
            <InteractionCard data={data} key={index} />
          ))}
        </section>
      </article>
    </section>
  );
};

export default FeatureDegard;
