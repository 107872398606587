import React from "react";
import { ForwardArrow } from "../../assets/icons/CareerPage/ForwardArrow";
import { SVGDot } from "../../assets/icons/svgDot";
import { useMediaQuery } from "react-responsive";
import "../../styles/components/Slices/CareerPage.scss";

export const ParentCard = ({
  data,
  containerRef,
  showBackIcon,
  showForwardIcon,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const scrollAmount = isMobile ? 280 : 390;

  const handleScroll = () => {
    containerRef.current?.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  const handleScrollBack = () => {
    containerRef.current?.scrollBy({ left: -scrollAmount, behavior: "smooth" });
  };

  return (
    <div className="bg-purple-1100 rounded-[18px] max-h-[351px] py-10 px-6 sm:p-10 min-w-[267px] sm:min-w-[367px]">
      <div className="flex flex-col pt-18 sm:pt-[67px]">
        <p className="text-white text-2xl sm:text-3xl px-5 sm:px-7 font-manrope font-semibold round_border py-3 rounded-full -tracking-[1.6px] bg-purple-350 max-w-fit">
          {data?.card_text?.text}
        </p>
        <div className="Background max-w-fit p-4 sm:p-[22px] flex flex-row gap-2 sm:gap-3 mt-5 ml-auto">
          <SVGDot />
          <SVGDot />
          <SVGDot />
        </div>
        <div className="flex flex-row items-center gap-5 justify-end pt-12 sm:pt-8">
          <p className="text-sm text-white opacity-80 sm:text-base">
            {data?.cta_text?.text}
          </p>
          {
            <div
              onClick={handleScrollBack}
              className={`cursor-pointer rotate-180 arrow-container ${showBackIcon ? "" : "arrow-container-hidden"}`}
            >
              <ForwardArrow />
            </div>
          }
          {
            <div
              onClick={handleScroll}
              className={`cursor-pointer arrow-container ${showForwardIcon ? "" : "arrow-container-hidden"}`}
            >
              <ForwardArrow />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
