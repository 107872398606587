import React, { useContext } from "react";
import { BookDemoIcon } from "../../../assets/icons/HompageQuizIcons/bookDemoIcon";
import { ProductionAI } from "../../../assets/icons/HompageQuizIcons/learnProductionAI";
import darkVector from "../../../assets/backgrounds/index-vector.png";
import "../../../styles/components/Slices/HomepageQuiz.scss";
import darkVectorMobile from "../../../assets/backgrounds/index-vector.png";
import darkVectorTablet from "../../../assets/backgrounds/index-vector.png";
import lightBgOlips from "../../../assets/backgrounds/blogOllips.png";

export const BlogQuizFinal = ({
  dark,
  link,
  setShowFinalComponent,
  setIsOpen,
  toggleModal,
  quizResultsData,
}) => {
  const handleModelOpen = () => {
    toggleModal();
    setIsOpen(false);
    setShowFinalComponent(false);
  };
  return (
    <section className="py-5">
      <section
        className={`relative flex flex-col overflow-hidden xl:min-h-[562px] max-w-6xl xl:max-h-[562px]  gap-5  sm:gap-8 py-10 sm:py-16 px-8 justify-center bg-white rounded-lg shadow-lg ${
          dark ? "quiz_dark_bg text-white" : "quiz_light_bg text-purple-1100"
        }`}
      >
        {!dark && (
          <img
            src={lightBgOlips}
            loading="eager"
            alt="BG Olips"
            className="absolute left-0 z-0 hidden lg:bottom-[-1px] lg:block "
          />
        )}
        {dark && (
          <>
            <img
              src={darkVector}
              loading="eager"
              alt="Swirl Vector"
              className="absolute left-0 z-0 hidden bottom-[-1px] lg:block"
            />
            <img
              src={darkVectorTablet}
              loading="eager"
              alt="Swirl Vector"
              className="absolute left-0 z-0 hidden bottom-32 sm:block lg:hidden"
            />
            <img
              src={darkVectorMobile}
              loading="eager"
              alt="Swirl Vector"
              className="absolute left-0 z-0 block bottom-32 sm:hidden"
            />
            <img
              src={lightBgOlips}
              loading="eager"
              alt="BG Olips"
              className="absolute left-0 z-0 hidden top-48 xl:top-22 lg:block"
            />
          </>
        )}
        <div className="flex flex-col justify-center items-center gap-2.5">
          <span
            className={`!text-xs text-center uppercase  ${
              dark ? "!text-purple-350" : " !text-purple-1500"
            }`}
          >
            {quizResultsData?.primary?.caption_title?.text}
          </span>
          <p
            className={`text-center !text-2xl sm:!text-3.25xl !my-0 !font-semibold !font-manrope sm:!leading-10 !tracking-tightest_7 ${
              dark ? "!text-white" : "!text-purple-1100"
            }`}
          >
            {quizResultsData?.primary?.title?.text}
          </p>
          {quizResultsData?.primary?.description?.text && (
            <p
              className={`text-center !text-2xl sm:!text-3.25xl !my-0 !font-semibold !font-manrope sm:!leading-10 !tracking-tightest_7 ${
                dark ? "!text-white" : "!text-purple-1100"
              }`}
            >
              {quizResultsData?.primary?.description?.text}
            </p>
          )}
        </div>

        <div className="z-0 flex flex-col items-center justify-center gap-5 sm:items-stretch sm:flex-row sm:gap-6">
          <button
            className={`flex flex-col box_class px-2.5 py-6 sm:py-7.5 min-w-[248px] max-w-[248px] items-center gap-5 z-20 ${
              dark ? " option_box_dark" : "option_box_light"
            }`}
            onClick={handleModelOpen}
          >
            {quizResultsData?.items[0]?.image?.url ? (
              <img
                width={71}
                height={74}
                src={quizResultsData?.items[0]?.image?.url}
                alt={quizResultsData?.items[0]?.image?.alt || "Icon"}
              />
            ) : (
              <BookDemoIcon />
            )}
            <span className="!text-base sm:!text-lg !font-manrope !-tracking-[0.9px] text-center !font-semibold justify-center lg:!text-white max-w-44 ">
              {quizResultsData?.items[0]?.title?.text}
            </span>
          </button>
          <a
            href={link}
            className={`flex max-w-[248px] px-2.5 py-6 sm:py-7.5 min-w-[248px] box_class flex-col items-center justify-center gap-5 ${
              dark ? " option_box_dark !text-white" : "option_box_light"
            }`}
          >
            {quizResultsData?.items[1]?.image?.url ? (
              <img
                width={71}
                height={74}
                src={quizResultsData?.items[1]?.image?.url}
                alt={quizResultsData?.items[1]?.image?.alt || "Icon"}
              />
            ) : (
              <ProductionAI />
            )}
            <span className="!text-base sm:!text-lg !font-manrope !-tracking-[0.9px] !font-semibold text-center justify-center lg:!text-white">
              {quizResultsData?.items[1]?.title?.text}
            </span>
          </a>
        </div>
      </section>
    </section>
  );
};
