import React from "react";
import "../../../styles/components/Pieces/FeatureMonetCard.scss";
import VideoComponent from "../../VideoComponent";

const FeatureMonetCard = ({ data }) => {
  return (
    <div className="flex flex-col justify-between min-w-[242px] sm:min-w-[368px] lightning_card_width overflow-hidden ">
      <div className="flex flex-col">
        {data?.icon?.url && (
          <img src={data?.icon?.url} width={32} height={32} className="mb-3" alt="feature monet card image" />
        )}
        <h1 className="mb-3 sm:mb-5 font-manrope max-w-[242px] sm:max-w-[368px] tracking-[-1.2px] leading-8 font-semibold text-2xl text-purple-1100 heading_height min-h-16">
          {data?.title?.text}
        </h1>
        <p
          className={`mb-4 sm:mb-5 lg:mb-4 min-h-24 sm:min-h-16 lg:min-h-24 text-sm lg:text-base  leading-5 lg:leading-6 text-gray-600 font-normal tracking-tight_1 font-inter`}
        >
          {data?.description?.text}
        </p>
        {/* <img
          src={data?.image?.url}
          className="rounded-xl w-[242px] sm:w-[368px] h-[250px] sm:h-[380px]"
          alt="img"
        /> */}

        {data?.video_link?.url ? (
          <div className="rounded-xl w-[242px] sm:w-[368px] h-[250px] sm:h-[380px]">
            <VideoComponent
              loop
              autoPlay
              muted
              className="rounded-xl w-[242px] sm:w-[368px] h-[250px] sm:h-[380px] object-fill"
            >
              <source
                src={data?.video_link?.url}
                type="video/mp4"
                className="rounded-xl"
              />
            </VideoComponent>
          </div>
        ) : (
          <>
            <img
              src={data?.image?.url}
              loading="eager"
              width={0}
              height={0}
              alt="stats"
              className="rounded-xl w-[242px] sm:w-[368px] h-[250px] sm:h-[380px]"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FeatureMonetCard;
