import React from "react";
import StatMonetCards from "../../Pieces/StatMonetCard";

const StatsMonet = ({slice}) => {
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="py-10 lg:py-20 lg:mx-11">
        <div className="flex flex-col justify-center items-center gap-2.5 pb-10">
          <h1 className="font-manrope text-2xl sm:text-3.25xl font-semibold leading-10 text-purple-1100 -tracking-[1.8px]">
            {slice?.primary?.section_title?.text}
          </h1>
          <p className="text-sm sm:text-base tracking-tight_1 text-gray-600 max-w-[770px] text-center">
          {slice?.primary?.descriptor_text?.text}
          </p>
        </div>
        <StatMonetCards data={slice?.items} />
      </article>
    </section>
  );
};
export default StatsMonet;
