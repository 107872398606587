export const blogPopUpData = [
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/6280e78b-bd84-44ba-9d3f-3fe9c2a487ea_apartment-icon.svg",
    labelText: "AI-assisted labeling",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/9eaf98be-8575-4484-b537-61da4840c6b7_data-exploration-icon.svg",
    labelText: "Data exploration",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/96dde470-ecb2-400c-b620-9d137368237d_encord-active-icon.svg",
    labelText: "Active learning",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/28de30d5-8615-4ca8-ae22-ade97b40fc4d_team-icon.svg",
    labelText: "Collaborative workflows",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/8bacaa6a-0418-45ff-9816-4485209d888c_tags-icon.svg",
    labelText: "Data curation",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/e1582570-529c-4c34-948b-38591aa97db8_dicom-slice-icon.svg",
    labelText: "Model evaluation",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/eabd5295-ecf9-4d29-8010-bd46997c3b6f_dot-chart-icon.svg",
    labelText: "Quality assurance",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/28cdd23c-e3e4-4b7e-9e0e-d0e72f67d9a6_outlier-detection-icon.svg",
    labelText: "Outlier detection",
  },
  {
    imgUrl:
      "https://encord.cdn.prismic.io/encord/0293954d-d8c7-42d0-af47-bf0a99a96372_solution-outlined-icon.svg",
    labelText: "Model fine-tuning",
  },
];
