import { Dialog, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import EaModalLeadForm from "./EaModalLeadForm";

const EaModal = ({ location }) => {
  const [eaModal, setEaModal] = useState(false);
  const [_closedFor, _setClosedFor] = useState("[]");

  const handleClose = () => {
    const _closedFor = localStorage.getItem("eaClosedFor") || "[]";
    const closedFor = JSON.parse(_closedFor);
    if (!closedFor.includes(location?.pathname)) {
      localStorage.setItem(
        "eaClosedFor",
        JSON.stringify([...closedFor, location?.pathname])
      );
    }
    setEaModal(false);
  };

  useEffect(() => {
    _setClosedFor(JSON.parse(localStorage.eaClosedFor || `[]`));
    setEaModal(true);
  }, []);
  return (
    <>
      <Transition
        appear
        show={eaModal && !_closedFor?.includes(location?.pathname)}
        as={Fragment}
      >
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <div className="w-full relative max-w-4xl 2xl:max-w-7xl flex flex-wrap gap-4 sm:gap-0 lg:flex-nowrap items-center justify-around transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all modal_container">
                <button
                  className="absolute right-8 top-8"
                  onClick={handleClose}
                >
                  <svg
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.153 10.5L21.8586 0.421933C22.0045 0.254448 21.8818 0 21.6563 0H19.0098C18.8539 0 18.7047 0.0676379 18.6019 0.183589L11.4219 8.49662L4.24186 0.183589C4.14237 0.0676379 3.99313 0 3.83394 0H1.18745C0.961938 0 0.839231 0.254448 0.985152 0.421933L9.69071 10.5L0.985152 20.5781C0.952465 20.6154 0.931494 20.6611 0.92473 20.7096C0.917966 20.7582 0.925692 20.8076 0.946992 20.852C0.968292 20.8965 1.00227 20.934 1.04489 20.9602C1.08752 20.9865 1.137 21.0003 1.18745 21H3.83394C3.98981 21 4.13905 20.9324 4.24186 20.8164L11.4219 12.5034L18.6019 20.8164C18.7014 20.9324 18.8506 21 19.0098 21H21.6563C21.8818 21 22.0045 20.7455 21.8586 20.5781L13.153 10.5Z"
                      fill="black"
                      fill-opacity="0.45"
                    />
                  </svg>
                </button>
                <section className="lg:max-w-xs">
                  <section className="flex gap-2.5 mb-2">
                    <img
                      src="https://encord.cdn.prismic.io/encord/290882be-1042-4df7-906e-5f2b6166d549_ea-logo-modal.svg"
                      alt="encord active logo"
                    />
                    <img
                      src="https://encord.cdn.prismic.io/encord/b0c32805-0a40-48ad-a1e9-c82e6b87d3b9_encord-active-text-logo.svg"
                      alt="encord active logo"
                    />
                  </section>
                  <a
                    href="https://www.producthunt.com/posts/encord-active?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-encord&#0045;active"
                    target="_blank"
                  >
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400645&theme=light"
                      alt="Encord&#0032;Active - Search&#0032;Engine&#0032;for&#0032;your&#0032;Visual&#0032;Data&#0032;&#0040;powered&#0032;by&#0032;ChatGPT&#0041; | Product Hunt"
                      style={{ width: "250px", height: "54px" }}
                      width="250"
                      height="54"
                    />
                  </a>
                  <h1 className="text-4xl lg:text-7xl font-semibold mt-14">
                    Join the waitlist
                  </h1>
                  <h6 className="text-lg 2xl:text-xl font-semibold mt-4 mb-8">
                    for the Search Anything Model
                  </h6>
                  <EaModalLeadForm
                    location={location}
                    btnText="Join now"
                    size="md"
                    handleClose={handleClose}
                  />

                  <section className="flex justify-between items-center mt-8">
                    <Link
                      to="https://github.com/encord-team/encord-active/"
                      target="_blank"
                      className="btn-sm text-black bg-gray-500 hover:bg-gray-400 mr-4"
                    >
                      <img
                        className="mr-2 w-5 h-auto"
                        src="https://encord.cdn.prismic.io/encord/0e203f57-bcdc-4d26-8db8-393ce29823f8_svgviewer-output+%281%29.svg"
                        alt="GitHub"
                        width={0}
                        height={0}
                      />
                      <svg
                        aria-hidden="true"
                        className="star-icon"
                        height="16"
                        viewBox="0 0 16 16"
                        version="1.1"
                        width="16"
                        data-view-component="true"
                      >
                        <path
                          fill="#eac54f"
                          d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25z"
                        ></path>
                      </svg>
                    </Link>
                    {/* <Link
                      className="flex gap-1 items-center text-sm text-purple-500 font-semibold"
                      to="https://app.encord.com/login"
                    >
                      Try demo
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.32423 4.99183L0.59842 0.916314C0.422195 0.76491 0.164062 0.901422 0.164062 1.1459V9.29694C0.164062 9.54142 0.422195 9.67793 0.59842 9.52653L5.32423 5.45101C5.4595 5.33435 5.4595 5.10849 5.32423 4.99183Z"
                          fill="#5E44FF"
                        />
                      </svg>
                    </Link> */}
                  </section>
                </section>

                <img
                  width={500}
                  height={600}
                  src="https://images.prismic.io/encord/48a669bc-5cf0-49ab-9a90-7a4cdc31c78a_search-anything-model.png?auto=compress,format"
                  alt="banner"
                  className=""
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EaModal;
