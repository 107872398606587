import React from "react";
import "../../styles/components/CTAs.scss";
import BofuCTAForm from "../BofuCtaForm";

const BofuCTA3 = () => {
  return (
    <section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 items-center bofu3_animated_border relative">
      <section class="flex flex-col flex-[0.58] justify-center gap-3 mx-auto px-4 sm:px-0 sm:py-8 xl:ml-6">
        <div class="text-2xl sm:text-2.5xl text-purple-1100 font-manrope tracking-tightest_6 font-semibold">
          The leading AI data curation & labeling platform
        </div>

        <div class="text-sm -tracking-wider text-gray-600 font-normal font-inter">
          Evaluate and validate your models against your data to surface,
          curate, and prioritize the most valuable data for training and
          fine-tuning to supercharge model performance.
        </div>

        <div class="flex">
          <BofuCTAForm
            buttonText={`Get started`}
            redirectUrl="https://encord.com/try-it-free/?&utm_campaign=Blog-CTA-BOFU-3"
          />
        </div>
      </section>

      <section class="flex-[0.42] md:rounded-tr-[12px] rounded-tl-[20px] md:rounded-tl-[12px] rounded-tr-[20px] overflow-hidden">
        <img
          src="https://images.prismic.io/encord/ZvzYKLVsGrYSwQ2l_Bofu3.png?auto=format,compress"
          height="747"
          width="949"
          alt="medical banner"
          class="h-full md:rounded-tr-[12px] rounded-tl-[20px] md:rounded-tl-[12px] rounded-tr-[20px]"
        />
      </section>
    </section>
  );
};

export default BofuCTA3;
