import React, { useEffect, useState } from "react";

const ReadingProgress = ({ target }) => {
  const [readingProgress, setReadingProgress] = useState(0);
  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element = target.current;
    const totalHeight =
      element.clientHeight - element.offsetTop + window.innerHeight;
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (windowScrollTop === 0) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100);
    }

    setReadingProgress((windowScrollTop / totalHeight) * 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <div
      className={` lg:bg-transparent px-4 sm:px-12 lg:px-0 top-[72px] w-full lg:top-[72px] max-w-6xl mx-auto py-4`}
    >
      <div className=" bg-gray-2200">
        <div
          className={`reading-progress-bar `}
          style={{ width: `${readingProgress}%` }}
        />
      </div>
    </div>
  );
};

export default ReadingProgress;
