import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/FeatureMonet.scss";
import FeatureMonetCard from "../../Pieces/FeatureMonetCard";
import HeadingSection from "../../Pieces/HeadingSection";

const FeatureMonet = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  return (
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <article className="py-10 lg:py-20 lg:mx-11">
          <HeadingSection data={slice?.primary} toggleModal={toggleModal} />
          <section className="flex flex-row gap-6 overflow-x-auto hideScrollBar scroll-smooth cs_slide_opacity_right">
            {slice?.items?.map((data, index) => (
              <FeatureMonetCard data={data}  key={index} />
            ))}
          </section>
        </article>
      </section>
    
  );
};

export default FeatureMonet;
