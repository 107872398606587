import React from "react";
import "swiper/css";
import "../../../styles/page/annotation_pages.scss";
import defaultIcon from "../../../assets/defaultAvatar.png";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TestimonialCard = ({ data, index, cardColor }) => {
  return (
    <div
      className={`${cardColor === "white" ? "bg-white border-gray-1600 customer_story_card_testimonial" : "bg-gray-1500 border-gray-[#E7E7E7] customer_story_card_testimonial_gray"} py-3 justify-between  rounded-xl sm:min-w-[364px] min-w-[254px] flex flex-col border border-solid ease-in-out duration-500`}
    >
      <div className="flex flex-col justify-between">
        <div className="flex flex-row items-center gap-4 px-5 pb-3 ">
          {data?.author_image?.url ? (
            <GatsbyImage
              image={getImage(data?.author_image)}
              loading="lazy"
              alt="avatar"
              className="rounded-full object-cover h-10 w-10"
            />
          ) : (
            <img
              src={defaultIcon}
              loading="lazy"
              width={40}
              height={40}
              alt="avatar"
              className="bg-transparent rounded-full "
            />
          )}
          <div className="flex flex-col">
            <span className="text-sm sm:text-base text-[#1D1B20] font-medium tracking-[0.4px] ">
              {data?.author?.text}
            </span>
            <span className="text-xs font-normal leading-4 text-gray-600 sm:text-sm max-h-4 tracking-tighter_1">
              {data?.job_designation?.text}
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-between px-5">
          <div className="flex-col justify-between block ">
            <p className="mt-3 text-sm font-normal sm:text-base text-gray-1200 line-clamp-5">
              {data?.description?.text}
            </p>
          </div>
        </div>
      </div>
      {data?.showlogo && (
        <div className="flex items-center justify-end mt-2">
          <img
            src={data?.company_logo?.url}
            loading="lazy"
            alt="avatar"
            className="max-w-[120px] max-h-8  px-1 py-1.5 mr-5"
          />
        </div>
        
      )}
    </div>
  );
};

export default TestimonialCard;
