import React, { useState } from "react";
import CustomButton from "../../CustomButton";
import { useMediaQuery } from "react-responsive";

const NextArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
      fill="#434343"
    />
  </svg>
);

const TestimonialTurnerCard = ({ cardData, index }) => {
  const isMobile = useMediaQuery({ minWidth: 768 });
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  return (
    <div className={`flex md:h-[400px]`}>
      <div
        key={index}
        onMouseEnter={isMobile ? () => setHoveredCardIndex(index) : undefined}
        onMouseLeave={isMobile ? () => setHoveredCardIndex(null) : undefined}
        className={`caseStudy_section_gradient px-6 pb-6 rounded-2xl sm:rounded-2.5xl justify-between flex flex-col border border-solid border-gray-1600 ease-linear duration-300 min-w-[242px] sm:min-w-[372px] max-w-[372px] ${hoveredCardIndex === index ? "lg:h-[400px]" : "h-[410px] sm:h-[400px] lg:h-[365px]"}`}
      >
        <div className="flex flex-col justify-center gap-5 py-8 border-b-[1px] border-b-gray-3001">
          {cardData?.company_logo?.url && (
            <div className="self-end">
              <img
                src={cardData?.company_logo?.url}
                loading="lazy"
                alt="avatar"
                className="h-4 sm:h-[22px]"
              />
            </div>
          )}

          <div
            className={`text-white font-manrope text-2xl sm:text-2.5xl min-h-[108px] sm:min-h-auto tracking-tight_1 sm:tracking-[-1.5px] font-bold sm:leading-9 span_style`}
            dangerouslySetInnerHTML={{
              __html: cardData?.title?.html,
            }}
          />
        </div>
        <div className="pt-3 pb-6">
          <h6
            className={`text-white sm:leading-tight text-base  tracking-[0.4px] font-medium leading-6`}
          >
            {cardData?.author?.text}
          </h6>

          <h6
            className={`text-gray-1400 sm:leading-tight text-sm -tracking-[0.5px] font-normal leading-5`}
          >
            {cardData?.designation?.text}
          </h6>
        </div>
        {hoveredCardIndex === index && (
          <div className="hidden ease-linear md:block">
            <CustomButton
              text={cardData?.cta_text?.text}
              link={cardData?.cta_link?.url}
              icon={<NextArrow />}
              buttonClass="bg-gray-1500 hover:text-gray-2700 hover:bg-gray-1600 text-gray-2500"
              direction="right"
            />
          </div>
        )}
        <div className="block md:hidden">
          <CustomButton
            text={cardData?.cta_text?.text}
            link={cardData?.cta_link?.url}
            icon={<NextArrow />}
            buttonClass="bg-gray-1500 hover:text-gray-2700 hover:bg-gray-1600 text-gray-2500"
            direction="right"
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialTurnerCard;
