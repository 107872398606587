import React from "react";
import "../../../styles/components/Pieces/FeatureRaphaelCard.scss";
import VideoComponent from "../../VideoComponent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeatureRaphaelCard = ({ cardData }) => {
  return (
    <div
      className={`bg-white border border-gray-1600 rounded-2xl flex- flex-col p-[18px] sm:p-7 lg:max-w-[556px] featureRaphaelCard `}
    >
      {cardData?.card_video ? (
        <VideoComponent
          src={cardData?.card_video?.url}
          className="w-auto h-[311px]"
        />
      ) : (
        <div className="flex sm:p-0 aspect-[5/3]">
          <GatsbyImage
            image={getImage(cardData?.image)}
            loading="lazy"
            alt={cardData?.card_label?.text || "feature raphael card image"}
            className="w-[100%] h-auto aspect-[5/3] rounded-xl"
          />
        </div>
      )}
      <div className="flex flex-col gap-3.5 mt-5 ">
        <div
          className={`font-manrope text-2xl -tracking-[1.2px] text-purple-1100 font-semibold sm:p-0 `}
        >
          {cardData?.title?.text}
        </div>
        <div className="text-base font-normal text-gray-2500 tracking-tight_1 sm:p-0">
          {cardData?.description?.text}
        </div>
      </div>
    </div>
  );
};

export default FeatureRaphaelCard;
