import React from "react";
import TestimonialCard from "../TestimonialCard";
import "../../../styles/components/logoMarquee.scss";
import { LOGOS_DURATION } from "../../../constants";

const TestimonialCardsMarquee = ({
  data,
  cardColor,
  duration,
  leftToRight,
}) => {
  return (
    <div className="tag-list gap_class">
      <InfiniteLoopSlider
        duration={duration || LOGOS_DURATION}
        reverse={leftToRight}
      >
        {data?.map((data, index) => (
          <TestimonialCard index={index} cardColor={cardColor} data={data} />
        ))}
      </InfiniteLoopSlider>

      <div className="fade" />
    </div>
  );
};

export default TestimonialCardsMarquee;

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};
