import React from "react";
import { _slugify, sanatizeSearchText } from "../../utilities/helpers";
import { Link } from "gatsby";
import "../../styles/page/annotation_pages.scss";
import { mainTags } from "../../constants";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Tag from "../V3/Blog/Tag";
import { compactObject } from "../../utilities/CompactObject";

/**
 * @summary A single Customer card
 * @param {Object} {blog} a blog object from prismic
 * @returns JSX.Element
 */
const CustomerCard = ({ blog, location, searchText, list = false }) => {
  const regex = new RegExp(sanatizeSearchText(searchText), "iu");
  const params = new URLSearchParams(location?.search);
  const _tag = params.get("tag");
  const title = blog?.node?.data?.title?.text?.replace(
    regex,
    `<span style="background:yellow">${blog?.node?.data?.title?.text?.match(
      regex
    )}</span>`
  );

  const { image, sub_image, stats } = blog?.node?.data || {};
  return (
    <Link
      to={
        blog?.node?.tags?.includes("Customers")
          ? `/customers/${blog?.node?.uid}/`
          : `/blog/${blog?.node?.uid}/`
      }
      className={`flex slider_card ${list ? "md:flex-row flex-col gap-6 " : "flex-col "} h-[100%] rounded-[18px] w-full mx-auto border border-gray-1600 bg-white overflow-hidden`}
    >
      <div
        className={`flex ${list ? "md:flex-row flex-col md:gap-5" : "flex-col"} `}
      >
        <GatsbyImage
          image={getImage(image)}
          alt={`sampleImage_${blog?.node?.uid}`}
          className={`object-cover aspect-video m-[1px] ${list ? "max-w-[unset] md:max-w-[400px] xl:max-w-[unset] md:rounded-tl-[18px] md:rounded-bl-[18px]" : "md:rounded-tl-[18px] md:rounded-tr-[18px]"}`}
        />

        <div
          className={`flex flex-1 flex-col sm:px-8 sm:pt-5 sm:pb-8 p-5  ${list ? "gap-3.5 sm:gap-5 justify-center md:p-5" : "gap-3.5"} `}
        >
          <div className="flex flex-wrap items-center gap-1 lg:gap-2.5 w-full">
            {blog?.node?.tags?.map((tag, i) => {
              const slug = _slugify(tag);
              const query = compactObject({
                tag: _tag ? _tag : mainTags.includes(tag) ? slug : "",
                sub: mainTags.includes(tag) ? "" : slug,
              });
              return (
                <>
                  {blog?.node?.tags?.includes("Customers") ? (
                    <div
                      key={`customers_tag_${i}`}
                      className="text-sm font-semibold tracking-wider text-purple-500 uppercase"
                    >
                      <Tag text={tag} />
                    </div>
                  ) : (
                    <Link
                      key={`blog_tag_${i}`}
                      state={{ prevPath: location?.pathname }}
                      to={`/blog?${new URLSearchParams(query).toString()}`}
                      className="text-sm font-semibold tracking-wider text-purple-500 uppercase"
                    >
                      <Tag text={tag} />
                    </Link>
                  )}
                </>
              );
            })}
          </div>
          {sub_image && (
            <GatsbyImage
              image={getImage(sub_image)}
              alt={`sampleImage_${blog?.node?.uid}`}
              className="max-w-[80px] min-h-[42px] max-h-[42px]"
              imgClassName="!object-scale-down"
              placeholder="blurred"
            />
          )}

          <span
            className="text-xl sm:text-2xl font-semibold line-clamp-3 text-purple-1100  font-manrope -tracking-[1.2px]"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />

          {/* {(list || (list && "sm:min-w-[768px]")) && ( */}
          <p
            className={`text-sm tracking-tighter_2 sm:text-lg text-gray-600 sm:tracking-tighter_3 line-clamp-2`}
            dangerouslySetInnerHTML={{
              __html: stats?.text,
            }}
          />
          {/* )} */}
        </div>
      </div>

      {/* {!list && "sm:min-w-[768px]" && (
        <p
          className={`text-sm tracking-tighter_2 sm:text-lg text-gray-600 ${
            list ? "" : "px-5 pb-5 pt-3.5"
          } sm:tracking-tighter_3  line-clamp-2`}
          dangerouslySetInnerHTML={{
            __html: title.text,
          }}
        />
      )} */}
    </Link>
  );
};

export default CustomerCard;
