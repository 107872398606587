import React from "react";
import { BLOCKED_DOMAINS } from "./blockedDomains";

export const mainTags = [
  "Company",
  "Data Quality",
  "Data Operations",
  "Healthcare",
  "Machine Learning",
  "Product",
  "Tutorials",
  "Video",
];
export const HUBSPOT_PROXY_URL =
  "https://europe-west2-cord-ai-platform.cloudfunctions.net/hubspot-proxy";
export const codeImages =
  "https://images.prismic.io/encord/e9283329-3826-438c-a040-5841f8b84825_feature-encord-model-import.png?auto=compress,format";
export const dashboardImages =
  "https://images.prismic.io/encord/e577b01e-69e8-4a7d-a800-646a8fa73a22_flower-segmentation.png?auto=compress,format";
export const codeImagesAlt = "code file";
export const dashboardImagesAlt = "dashboard image";

export const blockedDomains = BLOCKED_DOMAINS;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const VERIFY_EMAIL_API_KEY = "db21d67bdf434ca99c3003ad7a291bb0";
export const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const tailSliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const blogSliderSettings = {
  ...sliderSettings,
  nextArrow: (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0.625C8.85273 0.625 0.625 9.0765 0.625 19.4997C0.625 29.923 8.85273 38.3745 19 38.3745C29.1473 38.3745 37.375 29.923 37.375 19.4997C37.375 9.0765 29.1473 0.625 19 0.625ZM25.3451 19.7736L15.2553 27.2729C15.0379 27.433 14.7344 27.2729 14.7344 26.9991V25.0231C14.7344 24.5934 14.9354 24.1847 15.2758 23.9319L21.2395 19.4997L15.2758 15.0675C14.9354 14.8148 14.7344 14.4103 14.7344 13.9763V12.0004C14.7344 11.7265 15.0379 11.5664 15.2553 11.7265L25.3451 19.2259C25.5256 19.3607 25.5256 19.6388 25.3451 19.7736Z"
        fill="#D9D9D9"
      />
    </svg>
  ),
  prevArrow: (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0.625C29.1473 0.625 37.375 9.0765 37.375 19.4997C37.375 29.923 29.1473 38.3745 19 38.3745C8.85274 38.3745 0.625 29.923 0.625 19.4997C0.625 9.0765 8.85274 0.625 19 0.625ZM12.6549 19.7736L22.7447 27.2729C22.9621 27.433 23.2656 27.2729 23.2656 26.9991V25.0231C23.2656 24.5934 23.0646 24.1847 22.7242 23.9319L16.7605 19.4997L22.7242 15.0675C23.0646 14.8148 23.2656 14.4103 23.2656 13.9763V12.0004C23.2656 11.7265 22.9621 11.5664 22.7447 11.7265L12.6549 19.2259C12.4744 19.3607 12.4744 19.6388 12.6549 19.7736Z"
        fill="#D9D9D9"
      />
    </svg>
  ),
};

export const cardSliderSettings = {
  ...sliderSettings,
  nextArrow: (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_372_6096)">
        <path
          d="M23 1.5C13.3359 1.5 5.5 9.33594 5.5 19C5.5 28.6641 13.3359 36.5 23 36.5C32.6641 36.5 40.5 28.6641 40.5 19C40.5 9.33594 32.6641 1.5 23 1.5ZM29.043 19.2539L19.4336 26.207C19.2266 26.3555 18.9375 26.207 18.9375 25.9531V24.1211C18.9375 23.7227 19.1289 23.3437 19.4531 23.1094L25.1328 19L19.4531 14.8906C19.1289 14.6562 18.9375 14.2812 18.9375 13.8789V12.0469C18.9375 11.793 19.2266 11.6445 19.4336 11.793L29.043 18.7461C29.2148 18.8711 29.2148 19.1289 29.043 19.2539Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_372_6096"
          x="0.5"
          y="0.5"
          width="45"
          height="45"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_372_6096"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_372_6096"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  prevArrow: (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_372_6094)">
        <path
          d="M23 36.5C32.6641 36.5 40.5 28.6641 40.5 19C40.5 9.33594 32.6641 1.5 23 1.5C13.3359 1.5 5.5 9.33594 5.5 19C5.5 28.6641 13.3359 36.5 23 36.5ZM16.957 18.7461L26.5664 11.793C26.7734 11.6445 27.0625 11.793 27.0625 12.0469L27.0625 13.8789C27.0625 14.2773 26.8711 14.6563 26.5469 14.8906L20.8672 19L26.5469 23.1094C26.8711 23.3438 27.0625 23.7188 27.0625 24.1211L27.0625 25.9531C27.0625 26.207 26.7734 26.3555 26.5664 26.207L16.957 19.2539C16.7852 19.1289 16.7852 18.8711 16.957 18.7461Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_372_6094"
          x="0.5"
          y="0.5"
          width="45"
          height="45"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_372_6094"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_372_6094"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

export const SliderSettings = {
  ...sliderSettings,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  slidesToScroll: 1,
  arrows: false,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

export const toolkitSliderSettings = {
  ...sliderSettings,
  dots: false,
  infinite: true,
  speed: 2000,
  autoplay: true,
  swipeToSlide: false,
  swipe: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};

export const newCardSliderSettings = {
  ...sliderSettings,
  nextArrow: (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1444_77194)">
        <path
          d="M22.1444 1.83228C12.9399 1.83228 5.47656 9.29559 5.47656 18.5001C5.47656 27.7046 12.9399 35.1679 22.1444 35.1679C31.3489 35.1679 38.8122 27.7046 38.8122 18.5001C38.8122 9.29559 31.3489 1.83228 22.1444 1.83228ZM27.9 18.7419L18.7476 25.3644C18.5504 25.5058 18.2751 25.3644 18.2751 25.1226L18.2751 23.3777C18.2751 22.9982 18.4574 22.6373 18.7662 22.4141L24.1758 18.5001L18.7662 14.5861C18.4574 14.3629 18.2751 14.0057 18.2751 13.6225V11.8776C18.2751 11.6358 18.5504 11.4944 18.7476 11.6358L27.9 18.2583C28.0637 18.3773 28.0637 18.6229 27.9 18.7419Z"
          fill="#8C8C8C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1444_77194"
          x="0.714325"
          y="0.879828"
          width="42.8604"
          height="42.8602"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.80979" />
          <feGaussianBlur stdDeviation="2.38112" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1444_77194"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1444_77194"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  prevArrow: (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1444_77120)">
        <path
          d="M22.0509 35.1677C31.2554 35.1677 38.7188 27.7044 38.7188 18.4999C38.7188 9.29538 31.2554 1.83206 22.0509 1.83206C12.8464 1.83206 5.38309 9.29538 5.38308 18.4999C5.38308 27.7044 12.8464 35.1677 22.0509 35.1677ZM16.2953 18.2581L25.4477 11.6356C25.6449 11.4942 25.9202 11.6356 25.9202 11.8774L25.9202 13.6223C25.9202 14.0018 25.7379 14.3627 25.4291 14.5859L20.0195 18.4999L25.4291 22.4139C25.7379 22.6371 25.9202 22.9943 25.9202 23.3775L25.9202 25.1224C25.9202 25.3642 25.6449 25.5056 25.4477 25.3642L16.2953 18.7417C16.1316 18.6227 16.1316 18.3771 16.2953 18.2581Z"
          fill="#8C8C8C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1444_77120"
          x="0.620575"
          y="0.879584"
          width="42.8604"
          height="42.8602"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.80979" />
          <feGaussianBlur stdDeviation="2.38112" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1444_77120"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1444_77120"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

export const getTrailSliderSettings = {
  ...tailSliderSettings,
  nextArrow: (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1417_31500)">
        <path
          d="M16.483 1.18372C9.68156 1.18372 4.16675 6.69853 4.16675 13.5C4.16675 20.3014 9.68156 25.8162 16.483 25.8162C23.2844 25.8162 28.7992 20.3014 28.7992 13.5C28.7992 6.69853 23.2844 1.18372 16.483 1.18372ZM20.7359 13.6786L13.973 18.5722C13.8273 18.6766 13.6239 18.5722 13.6239 18.3935V17.1041C13.6239 16.8237 13.7586 16.557 13.9867 16.3921L17.984 13.5L13.9867 10.6078C13.7586 10.4429 13.6239 10.179 13.6239 9.8958V8.60645C13.6239 8.42775 13.8273 8.32328 13.973 8.42775L20.7359 13.3213C20.8569 13.4092 20.8569 13.5907 20.7359 13.6786Z"
          fill="#BFBFBF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1417_31500"
          x="0.647823"
          y="0.479931"
          width="31.6704"
          height="31.6703"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.81514" />
          <feGaussianBlur stdDeviation="1.75946" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1417_31500"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1417_31500"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  prevArrow: (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1417_31497)">
        <path
          d="M16.3315 1.18372C23.1329 1.18372 28.6477 6.69853 28.6477 13.5C28.6477 20.3014 23.1329 25.8162 16.3315 25.8162C9.53004 25.8162 4.01523 20.3014 4.01523 13.5C4.01523 6.69853 9.53004 1.18372 16.3315 1.18372ZM12.0785 13.6786L18.8414 18.5722C18.9872 18.6766 19.1906 18.5722 19.1906 18.3935V17.1041C19.1906 16.8237 19.0559 16.557 18.8277 16.3921L14.8304 13.5L18.8277 10.6078C19.0559 10.4429 19.1906 10.179 19.1906 9.8958V8.60645C19.1906 8.42775 18.9872 8.32328 18.8414 8.42775L12.0785 13.3213C11.9576 13.4092 11.9576 13.5907 12.0785 13.6786Z"
          fill="#BFBFBF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1417_31497"
          x="0.496212"
          y="0.479931"
          width="31.6704"
          height="31.6703"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.81514" />
          <feGaussianBlur stdDeviation="1.75946" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1417_31497"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1417_31497"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

export const SHOW_BANNER_PAGES = [];

export const EA_MODAL_PAGES = [];

export const learnSliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const learnDetailHeaderSlider = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
  arrows: false,
  className: "learning_slider",
};

export const learnSlidersSettings = {
  ...learnSliderSettings,
  nextArrow: (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0.625C8.85273 0.625 0.625 9.0765 0.625 19.4997C0.625 29.923 8.85273 38.3745 19 38.3745C29.1473 38.3745 37.375 29.923 37.375 19.4997C37.375 9.0765 29.1473 0.625 19 0.625ZM25.3451 19.7736L15.2553 27.2729C15.0379 27.433 14.7344 27.2729 14.7344 26.9991V25.0231C14.7344 24.5934 14.9354 24.1847 15.2758 23.9319L21.2395 19.4997L15.2758 15.0675C14.9354 14.8148 14.7344 14.4103 14.7344 13.9763V12.0004C14.7344 11.7265 15.0379 11.5664 15.2553 11.7265L25.3451 19.2259C25.5256 19.3607 25.5256 19.6388 25.3451 19.7736Z"
        fill="#D9D9D9"
      />
    </svg>
  ),
  prevArrow: (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0.625C29.1473 0.625 37.375 9.0765 37.375 19.4997C37.375 29.923 29.1473 38.3745 19 38.3745C8.85274 38.3745 0.625 29.923 0.625 19.4997C0.625 9.0765 8.85274 0.625 19 0.625ZM12.6549 19.7736L22.7447 27.2729C22.9621 27.433 23.2656 27.2729 23.2656 26.9991V25.0231C23.2656 24.5934 23.0646 24.1847 22.7242 23.9319L16.7605 19.4997L22.7242 15.0675C23.0646 14.8148 23.2656 14.4103 23.2656 13.9763V12.0004C23.2656 11.7265 22.9621 11.5664 22.7447 11.7265L12.6549 19.2259C12.4744 19.3607 12.4744 19.6388 12.6549 19.7736Z"
        fill="#D9D9D9"
      />
    </svg>
  ),
};

export const LOGOS_DURATION = 49495;
export const BLOG_POPUP_DURATION = 30000;
export const WHITE_PAPER_FINAL_PDF_LINK =
  "https://storage.googleapis.com/docs-media.encord.com/landing-page-docs/Maximizing%20AI%20ROI%20Why%20Data%20Matters%20Most.pdf";

export const FDA_GUIDE_PDF_LINK =
  "https://storage.googleapis.com/docs-media.encord.com/landing-page-docs/FDA%20Guide.pdf";

export const YOUTUBE_VOS_DATASET_PDF_LINK =
  "https://storage.googleapis.com/encord-active-sandbox-data/2eeda2-youtube-vos-encord.zip";

// ================= Career page data start ================
export const careers = [
  {
    category: "Sales",
    jobs: [
      {
        name: "Account Executive",
        work_model: "Hybrid",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/e8549e64-8ef9-4620-8768-45baa36965d8",
          },
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/78ccacfa-6aff-4658-a370-065dbae1d508",
          },
        ],
      },
      {
        name: "Commercial Associate",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/8a6252a5-ee52-4ac1-a05d-75e9a4e31fdf",
          },
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/673878a3-6ce3-4c01-b44c-0d62d668aacc",
          },
        ],
      },
      {
        name: "Sales Development Representative",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/1f4f1186-cd98-47a9-b4e4-35e1031f427b",
          },
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/739c988a-b251-4d68-9e74-77e020f76e33",
          },
        ],
      },
    ],
  },

  {
    category: "Product",
    jobs: [
      {
        name: "Technical Talent Acquisition Partner",
        work_model: "Hybrid",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/24fe62e4-5ec7-4b0f-a15a-cc81907a222f",
          },
        ],
      },
      {
        name: "Product Manager",
        work_model: "Hybrid",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/0ee9ad59-95f7-4a5f-beb5-5f2a31457c56",
          },
        ],
      },
      {
        name: "Machine Learning Solutions Engineer",
        work_model: "Hybrid",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/80f40fa8-34d5-4f61-a8b0-13db14755d35",
          },
        ],
      },
    ],
  },
  {
    category: "Engineering",
    jobs: [
      {
        name: "Full Stack Engineer",
        work_model: "Hybrid",
        job_type: "Full-time",
        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/f1daa229-4733-45ac-a28e-f65852b195d4",
          },
        ],
      },
      {
        name: "Engineer in Test",
        work_model: "Hybrid",
        job_type: "Full-time",
        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/0bc63765-184c-4462-94e6-9d6dc591fb5d",
          },
        ],
      },
      {
        name: "Machine Learning Engineer",
        work_model: "On-Site",
        job_type: "Full-time",
        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/63308ff8-52cc-4e5d-9fcb-f6726945d475",
          },
        ],
      },
      {
        name: "Junior DevOps Engineer",
        work_model: "On-Site",
        job_type: "Full-time",
        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/c398b64c-ab3f-4d65-b181-94a0cd66ba22",
          },
        ],
      },
    ],
  },
  {
    category: "Customer Success",
    jobs: [
      {
        name: "Senior Customer Success Manager",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/b57048c2-7ba0-4375-8b47-64b2b5c532b4",
          },
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/8d5ee45e-331b-4a9c-8f85-e5392c7c5964",
          },
        ],
      },
      {
        name: "Technical Customer Success Manager",
        work_model: "On-Site",
        job_type: "Full-time",
        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/986c6772-fe57-460f-bd24-e4646c51e801",
          },
        ],
      },
      {
        name: "Customer Support Engineer",
        work_model: "On-Site",
        job_type: "Full-time",
        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/c80122d9-34ed-4ae0-bfc1-c0d0bda8f0e4",
          },
        ],
      },
      {
        name: "Data Operations Specialist",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/3196a490-2097-43b4-9163-ba537def229a",
          },
        ],
      },
    ],
  },

  {
    category: "Growth",
    jobs: [
      {
        name: "GTM Talent Partner",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/661eabc2-de18-4d58-8425-57bffe8fa790",
          },
        ],
      },
      {
        name: "Senior GTM Recruiter",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/7489aba3-288f-4860-93b0-6b62fffcb99d",
          },
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/d9c45d2b-2a12-411b-a46a-21eb5623d0d1",
          },
        ],
      },
      {
        name: "Content & SEO Manager",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/e69f24e0-aebc-4a48-a7df-cd4b68bda579",
          },
        ],
      },
      {
        name: "Performance Marketing Manager",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/7733fd5a-dcd3-47ad-adbc-eb55aafd6754",
          },
        ],
      },
      {
        name: "Growth Marketing Manager",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/4037b0dc-c6a4-4f57-8f30-219d1183e724",
          },
        ],
      },
      {
        name: "AI/ML Developer Evangelist",
        work_model: "Hybrid",
        job_type: "Full-time",

        locations: [
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/a31ef98d-c2fa-427e-b092-46dfc7b1cfc0",
          },
        ],
      },
      {
        name: "Growth Manager",
        work_model: "On-Site",
        job_type: "Full-time",

        locations: [
          {
            location: "San Francisco",
            link: "https://jobs.lever.co/CordTechnologies/98c21bb3-6ce6-41b3-9941-416edfceb0be",
          },
          {
            location: "London",
            link: "https://jobs.lever.co/CordTechnologies/257dc7c0-9c73-4e2e-840f-59e21ca9d63b",
          },
        ],
      },
    ],
  },

  // {
  //   category: "operationsJobs",
  //   jobs: [
  //     {
  //       name: "Operations Analyst",
  //       locations: [
  //         { location: "UK (London)", link: "https://jobs.lever.co/CordTechnologies/2f4c58a9-0d5a-4f92-82a4-5087ff4aabe3 "}
  //       ]

  //     },
  //   ]
  // },

  // {
  //   category: "Other",
  //   jobs: [
  //     {
  //       name: "Former founders",
  //       work_model: "On-Site",
  //       job_type: "Full-time",

  //       locations: [
  //         {
  //           location: "London",
  //           link: "https://jobs.lever.co/CordTechnologies/f37c4063-1d3a-481d-a452-fd9cae373e11",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const benefits = [
  {
    heading: "Social events",
    paargraph: "Encord loves fun events with the team",
    img: "https://encord.cdn.prismic.io/encord/c48d8cfb-9849-485b-b4fd-9da2d7d1032e_career-page-social-event-logo.svg",
    alt: "social event logo",
  },
  {
    heading: "Flexible hours",
    paargraph: "Do your work when you are the most productive",
    img: "https://encord.cdn.prismic.io/encord/10e6d655-8cf3-4054-9947-c8d658b7e085_career-page-flexible-hours-logo.svg",
    alt: "flexible hours logo",
  },
  {
    heading: "Hybrid",
    paargraph: "Work from home and in the office",
    img: "https://encord.cdn.prismic.io/encord/0e3c0c6b-f5cb-4929-a081-5001f78acf2c_career-page-hybrid-logo.svg",
    alt: "hybrid logo",
  },
  {
    heading: "Fresh gear",
    paargraph: "We equip you with the newest Apple gear",
    img: "https://encord.cdn.prismic.io/encord/74ce4275-1386-41e8-b162-10f884340991_career-page-fresh-gear-logo.svg",
    alt: "fresh gear logo",
  },
  {
    heading: "Health insurance",
    paargraph: "Your health is our top priority",
    img: "https://encord.cdn.prismic.io/encord/66b7dbf5-289c-4495-88fa-20ceec5caba8_career-page-health-insurance-logo.svg",
    alt: "health insurance logo",
  },
  {
    heading: "Time off",
    paargraph: "Generous PTO that allows you to recharge",
    img: "https://encord.cdn.prismic.io/encord/627d831a-e1e4-43cc-b194-da15d4f4c10a_career-page-time-off-logo.svg",
    alt: "time off logo",
  },
  {
    heading: "Visa sponsorship",
    paargraph: "We sponsor visas for exceptional candidates",
    img: "https://encord.cdn.prismic.io/encord/01424d99-ce74-49fe-8760-97fa791f4650_career-page-visa-sponsorship-logo.svg",
    alt: "visa sponsorshi logo",
  },
  {
    heading: "Team lunches",
    paargraph: "We buy lunch for the office on Fridays",
    img: "https://encord.cdn.prismic.io/encord/48fc9e4e-f6a3-45a9-8c22-7a3eba069787_career-page-team-lunches-logo.svg",
    alt: "team lunches logo",
  },
  {
    heading: "Cycle to work",
    paargraph: "Benefit from our cycle to work scheme",
    img: "https://encord.cdn.prismic.io/encord/63591c08-3f02-45a6-9d02-06dcc8400d44_career-page-cycle-to-work-logo.svg",
    alt: "cycle to work logo",
  },
];
// ================= Career page data end ================
