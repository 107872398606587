import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ArrowDown } from "../assets/icons/arrowDownIcon";
import { AIAssisted, SAM, SDKIcon } from "../assets/icons/headerIcons";
// import {
//   AnnotatorTrainingIcon,
//   DicomIcon,
//   ECGIcon,
//   ImageIcon,
//   SynthaticIcon,
//   VideoIcon,
//   WorkFlowsIcon,
//   AudioIcon,
// } from "../assets/product-header-button";
import {
  ImageIcon,
  VideoIcon,
  DicomIcon,
  DocumentIcon,
  AudioIcon,
  DataCuration,
  DataLabeling,
  Agents,
  ModelLabeling,
} from "../assets/icons/NavbarIcons/Icons";

import CustomLink from "../components/CustomLink";
import { headerTheme } from "./Header";
import {
  Chevron,
  ForwardArrow,
  HeaderSubmenuIcon,
  NextIcon,
} from "../assets/icons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const platForm = [
  {
    heading: "Data Management & Curation",
    icon: <DataCuration />,
    hoveredIcon: <DataCuration fill={"#5E44FF"} />,
    link: "https://encord.com/encord-index/",
  },
  {
    heading: "Data Annotation",
    icon: <DataLabeling />,
    hoveredIcon: <DataLabeling fill={"#5E44FF"} />,
    link: "https://encord.com/annotate/",
  },
  {
    heading: "Model Evaluation",
    icon: <ModelLabeling />,
    hoveredIcon: <ModelLabeling fill={"#5E44FF"} />,
    link: "https://encord.com/active/",
  },
  {
    heading: "Agents & Data Workflows",
    icon: <Agents />,
    hoveredIcon: <Agents fill={"#5E44FF"} fill2={"#5E44FF"} />,
    link: "https://encord.com/ai-workflows-agents/",
  },
];
const modalities = [
  {
    heading: "Image",
    icon: <ImageIcon />,
    hoveredIcon: <ImageIcon fill={"#5E44FF"} />,
    link: "https://encord.com/image/",
  },
  {
    heading: "Video",
    icon: <VideoIcon />,
    hoveredIcon: <VideoIcon fill={"#5E44FF"} />,
    link: "https://encord.com/video/",
  },
  {
    heading: "Document & Text",
    icon: <DocumentIcon />,
    hoveredIcon: <DocumentIcon fill={"#5E44FF"} />,
    link: "https://encord.com/document/",
  },
  {
    heading: "Audio",
    icon: <AudioIcon />,
    hoveredIcon: <AudioIcon fill={"#5E44FF"} />,
    link: "https://encord.com/audio/",
  },
  {
    heading: "DICOM & NIfTI",
    icon: <DicomIcon />,
    hoveredIcon: <DicomIcon fill={"#5E44FF"} />,
    link: "https://encord.com/dicom/",
  },
];

// const exploreEngine = [
//   {
//     heading: "Index",
//     description: "Data curation & management",
//     imgSrc:
//       "https://encord.cdn.prismic.io/encord/cae9ab10-1ee2-4744-9ba1-8e8b1219eabd_index-icon.svg",
//     href: "/encord-index/",
//   },
//   {
//     heading: "Annotate",
//     description: "Annotation & workflow tools",
//     imgSrc:
//       "https://encord.cdn.prismic.io/encord/5c6ece91-090b-407e-8b86-bd4e289e8c2b_nav-bar-annotate-icon.svg",
//     href: "/annotate/",
//     subMenu: [
//       {
//         heading: "annotation",
//         items: [
//           {
//             text: "Image",
//             icon: <ImageIcon />,
//             href: "/image/",
//           },
//           {
//             text: "Video",
//             icon: <VideoIcon />,
//             href: "/video/",
//           },
//           {
//             text: "Audio",
//             icon: <AudioIcon />,
//             href: "/audio/",
//           },
//           {
//             text: "DICOM & NIfTI",
//             icon: <DicomIcon />,
//             href: "/dicom/",
//           },
//           {
//             text: "Synthetic-aperture radar",
//             icon: <SynthaticIcon />,
//             href: "/sar/",
//           },
//           {
//             text: "ECG annotation",
//             icon: <ECGIcon />,
//             href: "/ecg-annotation-tool/",
//           },
//         ],
//       },
//       {
//         heading: "QUALITY CONTROL & ASSURANCE",
//         items: [
//           {
//             text: "AI Agents & Workflows",
//             icon: <WorkFlowsIcon />,
//             href: "/ai-workflows-agents/",
//           },
//           {
//             text: "Annotator training module",
//             icon: <AnnotatorTrainingIcon />,
//             href: "/annotator-training/",
//           },
//         ],
//       },
//       {
//         heading: "automation",
//         items: [
//           {
//             text: "Segment Anything Model (SAM)",
//             icon: <SAM />,
//             href: "/auto-segment/",
//           },
//           // {
//           //   text: "AI-assisted labelling",
//           //   icon: <AIAssisted />,
//           //   href: "/automate/",
//           // },
//           {
//             text: "API & Python SDK",
//             icon: <SDKIcon />,
//             href: "/api/",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     heading: "Active",
//     description: "Model testing & evaluation",
//     imgSrc:
//       "https://encord.cdn.prismic.io/encord/e1c65c1a-f67f-42de-b0da-6f86c0927d57_nav-bar-active-icon.svg",
//     href: "/active/",
//   },
//   // {
//   //   heading: "Apollo",
//   //   description: "Fine-tune visual foundation models",
//   //   comingSoon: "true",

//   //   imgSrc:
//   //     "https://encord.cdn.prismic.io/encord/4a29c582-567f-414c-889d-1cce958345e4_nav-bar-apollo-icon.svg",
//   //   href: "/",
//   // },
// ];
// const platform = [
//   {
//     heading: "Index",
//     description: "Data curation & management",
//     imgSrc:
//       "https://encord.cdn.prismic.io/encord/cae9ab10-1ee2-4744-9ba1-8e8b1219eabd_index-icon.svg",
//     href: "/encord-index/",
//   },
//   {
//     heading: "Annotate",
//     description: "Annotation & workflow tools",
//     imgSrc:
//       "https://encord.cdn.prismic.io/encord/5c6ece91-090b-407e-8b86-bd4e289e8c2b_nav-bar-annotate-icon.svg",
//     href: "/annotate/",
//     subMenu: [
//       {
//         heading: "annotation",
//         items: [
//           {
//             text: "Image",
//             icon: <ImageIcon />,
//             href: "/image/",
//           },
//           {
//             text: "Video",
//             icon: <VideoIcon />,
//             href: "/video/",
//           },
//           {
//             text: "Audio",
//             icon: <AudioIcon />,
//             href: "/audio/",
//           },
//           {
//             text: "DICOM & NIfTI",
//             icon: <DicomIcon />,
//             href: "/dicom/",
//           },
//           {
//             text: "Synthetic-aperture radar",
//             icon: <SynthaticIcon />,
//             href: "/sar/",
//           },
//           {
//             text: "ECG annotation",
//             icon: <ECGIcon />,
//             href: "/ecg-annotation-tool/",
//           },
//         ],
//       },
//       {
//         heading: "QUALITY CONTROL & ASSURANCE",
//         items: [
//           {
//             text: "AI Agents & Workflows",
//             icon: <WorkFlowsIcon />,
//             href: "/ai-workflows-agents/",
//           },
//           {
//             text: "Annotator training module",
//             icon: <AnnotatorTrainingIcon />,
//             href: "/annotator-training/",
//           },
//         ],
//       },
//       {
//         heading: "automation",
//         items: [
//           {
//             text: "Segment Anything Model (SAM)",
//             icon: <SAM />,
//             href: "/auto-segment/",
//           },
//           // {
//           //   text: "AI-assisted labelling",
//           //   icon: <AIAssisted />,
//           //   href: "/automate/",
//           // },
//           {
//             text: "API & Python SDK",
//             icon: <SDKIcon />,
//             href: "/api/",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     heading: "Active",
//     description: "Model testing & evaluation",
//     imgSrc:
//       "https://encord.cdn.prismic.io/encord/e1c65c1a-f67f-42de-b0da-6f86c0927d57_nav-bar-active-icon.svg",
//     href: "/active/",
//   },
//   // {
//   //   heading: "Apollo",
//   //   description: "Fine-tune visual foundation models",
//   //   comingSoon: "true",

//   //   imgSrc:
//   //     "https://encord.cdn.prismic.io/encord/4a29c582-567f-414c-889d-1cce958345e4_nav-bar-apollo-icon.svg",
//   //   href: "/",
//   // },
// ];

export default function ProductHeaderButton({
  theme,
  handleEnter,
  handleLeave,
  ref,
  isHover,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  useEffect(() => {
    setHoveredIndex(0);
  }, [isHover]);
  const [platformActive, isPlatformActive] = useState(false);

  return (
    <Popover
      className="relative"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      ref={ref}
    >
      {({ open }) => (
        <>
          <div>
            <Popover.Button
              className={classNames(
                isHover
                  ? headerTheme[theme].hoverTextColor
                  : headerTheme[theme].textColor,
                "group rounded-md inline-flex gap-2 items-center text-sm lgxl_mid:text-base font-medium focus:outline-none"
              )}
            >
              <span>Platform</span>
              <ArrowDown
                fill={
                  isHover
                    ? headerTheme[theme].hoverIcon
                    : headerTheme[theme].icon
                }
              />
            </Popover.Button>

            <Transition
              show={isHover}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              null
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-[18%] xl:-translate-x-[20%] mt-6 px-2 w-screen sm:px-0 max-w-5xl xl:max-w-[1110px]">
                <div
                  className={`${
                    hoveredIndex === 1
                      ? "overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5 shadow-xl"
                      : ""
                  }`}
                >
                  <nav
                    className={`grid ${
                      hoveredIndex === 1
                        ? "grid-cols-3 bg-white"
                        : "grid-cols-1"
                    } xl:flex  `}
                    aria-labelledby="solutions-heading"
                  >
                    <section
                      className={`flex-auto bg-white p-2 ${
                        hoveredIndex == 1
                          ? "rounded-s-lg"
                          : "rounded-lg shadow-xl"
                      } h-auto max-w-fit`}
                    >
                      <section className="flex gap-1 flex-row">
                        <section className="flex-1 min-w-[278px]">
                          <p className="text-sm pl-2 pb-2 pt-1 font-medium text-[#AAAAAA]">
                            Platform
                          </p>
                          {platForm?.map((value, index) => {
                            return (
                              <a
                                key={index}
                                href={value?.link}
                                className="p-2 navbar-hover-bg flex items-center gap-2 justify-between group"
                              >
                                <div className="flex gap-1.5 items-center">
                                  {/* Display value.icon by default and value.hoveredIcon on hover */}
                                  <span className="group-hover:hidden">
                                    {value?.icon}
                                  </span>
                                  <span className="hidden group-hover:inline-block">
                                    {value?.hoveredIcon}
                                  </span>
                                  <p className="text-base text-gray-2500 group-hover:text-gray-2700 font-medium tracking-tighter_3">
                                    {value?.heading}
                                  </p>
                                </div>
                                <ForwardArrow
                                  fill={"#5E44FF"}
                                  className="opacity-0 group-hover:opacity-100"
                                />
                              </a>
                            );
                          })}
                        </section>

                        <section className=" flex-1 min-w-[278px]">
                          <p className="text-sm pl-2 pb-2 pt-1 font-medium text-[#AAAAAA]">
                            Modalities{" "}
                          </p>
                          {modalities?.map((value, index) => {
                            return (
                              <a
                                key={index}
                                href={value?.link}
                                className="p-2 navbar-hover-bg flex items-center group justify-between"
                              >
                                <div className="flex gap-1.5 items-center">
                                  {/* Display value.icon by default and value.hoveredIcon on hover */}
                                  <span className="group-hover:hidden">
                                    {value?.icon}
                                  </span>
                                  <span className="hidden group-hover:inline-block">
                                    {value?.hoveredIcon}
                                  </span>
                                  <p className="text-base text-gray-2500 group-hover:text-gray-2700 font-medium tracking-tighter_3">
                                    {value?.heading}
                                  </p>
                                </div>
                                <ForwardArrow
                                  fill={"#5E44FF"}
                                  className="hidden group-hover:inline-block"
                                />
                              </a>
                            );
                          })}
                        </section>
                      </section>
                    </section>

                    <>
                      {/* <section className="flex-[0.3] py-[30px] px-3 2xl:p-5 lg:max-h-[402px]">
                        <h4 className="text-sm font-medium uppercase text-gray-1200">
                          {exploreEngine[hoveredIndex]?.subMenu?.[0]?.heading}
                        </h4>
                        <ul className="flex flex-col mt-6 border-gray-1600 gap-y-3">
                          {exploreEngine[hoveredIndex]?.subMenu?.[0]?.items.map(
                            ({ text, href, icon }, index) => (
                              <li className="group">
                                <CustomLink
                                  to={href}
                                  key={index}
                                  className="flex p-2.5 gap-x-4 items-start rounded-lg  group-hover:bg-[#F5F5F5] outline-none transition ease-in-out duration-150"
                                >
                                  <div className="flex items-center justify-center flex-shrink-0 w-5 h-6 text-white hover:fill-pruple-500">
                                    {icon}
                                  </div>
                                  <p className="text-base font-medium text-gray-600">
                                    {text}
                                  </p>
                                </CustomLink>
                              </li>
                            )
                          )}
                        </ul>
                      </section> */}

                      {/* <section className="flex-[0.3] py-[30px] px-3 2xl:p-5 flex flex-col gap-6">
                        {exploreEngine[hoveredIndex]?.subMenu
                          ?.slice(1, 3)
                          ?.map((subMenu, index) => (
                            <section>
                              <h4
                                className="text-sm font-medium uppercase text-gray-1200"
                                key={index}
                              >
                                {subMenu?.heading}
                              </h4>
                              <ul className="flex-col mt-6 border-gray-1600flex gap-y-3">
                                {subMenu?.items.map(
                                  ({ text, href, icon }, index) => (
                                    <li className="group">
                                      <CustomLink
                                        to={href}
                                        key={index}
                                        className="flex p-2.5 gap-x-4 items-start rounded-lg  group-hover:bg-[#F5F5F5] outline-none transition ease-in-out duration-150"
                                      >
                                        {icon}
                                        <p className="text-base font-medium text-gray-600">
                                          {text}
                                        </p>
                                      </CustomLink>
                                    </li>
                                  )
                                )}
                              </ul>
                            </section>
                          ))}
                      </section> */}
                    </>
                  </nav>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
}
