import React from "react";
import { Link } from "gatsby";

const SocialMediaHandles = ({iconColor="white", containerClass }) => {
  return (
    <div
      className={`gap-[22px] lg:justify-center md:justify-start flex ${containerClass}`}
    >
      <Link to="https://twitter.com/encord_team/" target="_blank">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill='none'
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2716 1.58655H18.0828L11.9411 8.60612L19.1663 18.1582H13.509L9.07804 12.3649L4.00796 18.1582H1.19503L7.7642 10.65L0.833008 1.58655H6.63393L10.6392 6.88182L15.2716 1.58655ZM14.2849 16.4755H15.8427L5.7875 3.18082H4.11589L14.2849 16.4755Z"
            fill={iconColor}
          />
        </svg>
      </Link>
      <Link to="https://www.linkedin.com/company/encord-team/" target="_blank">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_316_1419)">
            <path
              d="M18.5195 0H1.47656C0.660156 0 0 0.644531 0 1.44141V18.5547C0 19.3516 0.660156 20 1.47656 20H18.5195C19.3359 20 20 19.3516 20 18.5586V1.44141C20 0.644531 19.3359 0 18.5195 0ZM5.93359 17.043H2.96484V7.49609H5.93359V17.043ZM4.44922 6.19531C3.49609 6.19531 2.72656 5.42578 2.72656 4.47656C2.72656 3.52734 3.49609 2.75781 4.44922 2.75781C5.39844 2.75781 6.16797 3.52734 6.16797 4.47656C6.16797 5.42188 5.39844 6.19531 4.44922 6.19531ZM17.043 17.043H14.0781V12.4023C14.0781 11.2969 14.0586 9.87109 12.5352 9.87109C10.9922 9.87109 10.7578 11.0781 10.7578 12.3242V17.043H7.79688V7.49609H10.6406V8.80078H10.6797C11.0742 8.05078 12.043 7.25781 13.4844 7.25781C16.4883 7.25781 17.043 9.23438 17.043 11.8047V17.043Z"
              fill={iconColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_316_1419">
              <rect width="20" height="20"  fill={iconColor} />
            </clipPath>
          </defs>
        </svg>
      </Link>
      <Link
        to="https://www.youtube.com/channel/UCySY39IUFp5yEReD2rSEXNg/"
        target="_blank"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.8008 6C19.8008 6 19.6055 4.62109 19.0039 4.01563C18.2422 3.21875 17.3906 3.21484 17 3.16797C14.2031 2.96484 10.0039 2.96484 10.0039 2.96484H9.99609C9.99609 2.96484 5.79688 2.96484 3 3.16797C2.60938 3.21484 1.75781 3.21875 0.996094 4.01563C0.394531 4.62109 0.203125 6 0.203125 6C0.203125 6 0 7.62109 0 9.23828V10.7539C0 12.3711 0.199219 13.9922 0.199219 13.9922C0.199219 13.9922 0.394531 15.3711 0.992187 15.9766C1.75391 16.7734 2.75391 16.7461 3.19922 16.832C4.80078 16.9844 10 17.0313 10 17.0313C10 17.0313 14.2031 17.0234 17 16.8242C17.3906 16.7773 18.2422 16.7734 19.0039 15.9766C19.6055 15.3711 19.8008 13.9922 19.8008 13.9922C19.8008 13.9922 20 12.375 20 10.7539V9.23828C20 7.62109 19.8008 6 19.8008 6ZM7.93359 12.5938V6.97266L13.3359 9.79297L7.93359 12.5938Z"
            fill={iconColor}
          />
        </svg>
      </Link>
      <Link
        to="https://encordactive.slack.com/join/shared_invite/zt-24z7s3k0k-k8IcEU4iMz93erouJtVCPA#/shared-invite/email"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          fill="none"
        >
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M7.333 0a2 2 0 1 0 0 4h2.001V2a2.001 2.001 0 0 0-2.001-2Zm0 5.333H2a2 2 0 0 0 0 4h5.333a2 2 0 1 0 0-4ZM20 7.333a2 2 0 0 0-4 0v2h2a2 2 0 0 0 2-2Zm-5.333 0V2a2 2 0 0 0-4 0v5.333a2 2 0 1 0 4 0ZM12.666 20a2 2 0 1 0 0-4h-2v2c0 1.103.895 1.998 2 2Zm0-5.334H18a2 2 0 0 0 0-4h-5.333a2 2 0 0 0 0 4ZM0 12.666a2 2 0 0 0 4 0v-2H2a2 2 0 0 0-2 2Zm5.334 0V18a2 2 0 0 0 4 0v-5.332a2 2 0 0 0-4-.002Z"
            clipRule="evenodd"
          />
        </svg>
      </Link>
    </div>
  );
};

export default SocialMediaHandles;
