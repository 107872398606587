import React from "react";

const RightHeaderArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="21"
      viewBox="0 0 178 21"
      fill="none"
    >
      <path
        className="moving_dots"
        d="M176.565 21.0002V1.2002H0.165433"
        stroke="url(#paint0_linear_211_12793)"
        strokeWidth="1.2"
        strokeDasharray="2.4 2.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="0"
          y1="0"
          x2="178"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5E44FF" />
          <stop offset="0.5" stopColor="#BFBFBF" />
          <stop offset="1" stopColor="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RightHeaderArrow;
