import * as React from "react";
import { Chevron } from "../../../assets/icons";
import CustomButton from "../../CustomButton";

const SectionPollockCard = ({
  sectionHeading,
  sectionDescription,
  sectionVideo,
  sectionImage,
  linkText,
  linkUrl,
  assetClass,
  showCta,
  cardContainerClass,
}) => {
  return (
    <section
      className={`flex flex-col w-full h-full overflow-hidden text-left bg-white border rounded-3xl border-gray-1600 ${cardContainerClass}`}
    >
      <div className={`flex items-center ${assetClass} bg-gray-1600`}>
        {sectionVideo ? (
          <VideoComponent
            loop
            autoPlay
            muted
            className={`w-auto h-full my-auto`}
          >
            <source src={sectionVideo} type="video/mp4" />
          </VideoComponent>
        ) : (
          <img
            loading="lazy"
            src={sectionImage?.url}
            alt={sectionImage?.alt || "ai-assistant-label"}
            className={`h-full lg:h-auto`}
          />
        )}
      </div>
      <div className="px-3.5 py-5 sm:p-6">
        {sectionHeading && (
          <h4
            className={`text-2xl sm:text-2.5xl tracking-tightest_5 sm:tracking-tightest_6 font-manrope font-semibold text-purple-1100 mb-3 sm:mb-[18px] lg:mb-3`}
          >
            {sectionHeading}
          </h4>
        )}
        {sectionDescription && (
          <p className="mb-5 text-sm font-normal sm:text-base sm:mb-6 lg:mb-3 font-inter -tracking-wider sm:tracking-tight_1 text-gray-2500">
            {sectionDescription}
          </p>
        )}
        {showCta && (
          <CustomButton
            link={linkUrl}
            icon={<Chevron />}
            text={linkText}
            direction="right"
            buttonClass="bg-gray-1600 hover:bg-gray-2900 text-gray-2500"
          />
        )}
      </div>
    </section>
  );
};

export default SectionPollockCard;
