/**
 * @summary Function to format the date in following format e.g `November 20, 2022`
 * @param {Date} date Date to format
 * @returns Formatted date in string
 * @author Wajih Aslam
 */
const formatDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateObj = new Date(date);
  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  return `${month} ${day} ${year}`;
};
export const formatDateYear = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateObj = new Date(date);
  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const formatDateYearShort = (time) => {
  const date = new Date(time);
  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const formattedDateCustom = formattedDate.replace(/,/g, "");

  return formattedDateCustom;
};

export const formatToBSDate = (date) => {
  // Convert UTC to BST (British Summer Time)
  const bstDate = new Date(date);

  // Format the date
  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const time = bstDate.toLocaleString("en-US", options);
  return time.split("GMT+5");
};

export const formatTimeZoneToDate = (date) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const usDate = new Date(
    new Date(date).toLocaleString("en-US", { timeZone: timeZone })
  );

  // Format the date
  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short",
    timeZone: timeZone,
  };
  const time = usDate.toLocaleString("en-US", options);

  return time.split("GMT+1");
};

export default formatDate;

export const formatCombineDate = (startDate, endDate) => {
  let startDt = new Date(startDate);
  let endDt = new Date(endDate);
  let options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  let startFormatted = startDt.toLocaleString("en-US", options);
  let endFormatted = endDt.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  let formattedTimeRange = `${startFormatted} - ${endFormatted}`;
  return formattedTimeRange;
};

export const formatCombineUSDate = (startDate, endDate) => {
  let startDt = new Date(startDate);
  let endDt = new Date(endDate);
  let options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  let startFormatted = startDt.toLocaleString("en-US", options);
  let endFormatted = endDt.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  let formattedTimeRange = `${startFormatted} - ${endFormatted}`;
  return formattedTimeRange;
};
