import React from "react";

export const RegisteredIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={76} height={75} fill="none" >
        <path
            fill="#73D13D"
            fillRule="evenodd"
            d="M38 75c20.71 0 37.5-16.79 37.5-37.5S58.71 0 38 0 .5 16.79.5 37.5 17.29 75 38 75ZM27.1 34.063c.301.125.575.308.805.54l7.21 7.21 13.56-13.553a2.342 2.342 0 0 1 3.315 0l.193.193a2.342 2.342 0 0 1 0 3.316L36.777 47.176a2.34 2.34 0 0 1-3.316 0l-9.065-9.065a2.476 2.476 0 0 1 0-3.509 2.474 2.474 0 0 1 2.704-.54Z"
            clipRule="evenodd"
        />
    </svg>
);
