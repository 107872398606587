exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accelerate-jsx": () => import("./../../../src/pages/accelerate.jsx" /* webpackChunkName: "component---src-pages-accelerate-jsx" */),
  "component---src-pages-active-jsx": () => import("./../../../src/pages/active.jsx" /* webpackChunkName: "component---src-pages-active-jsx" */),
  "component---src-pages-annotate-jsx": () => import("./../../../src/pages/annotate.jsx" /* webpackChunkName: "component---src-pages-annotate-jsx" */),
  "component---src-pages-annotation-teams-jsx": () => import("./../../../src/pages/annotation-teams.jsx" /* webpackChunkName: "component---src-pages-annotation-teams-jsx" */),
  "component---src-pages-annotator-training-jsx": () => import("./../../../src/pages/annotator-training.jsx" /* webpackChunkName: "component---src-pages-annotator-training-jsx" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-auto-segment-jsx": () => import("./../../../src/pages/auto-segment.jsx" /* webpackChunkName: "component---src-pages-auto-segment-jsx" */),
  "component---src-pages-automate-js": () => import("./../../../src/pages/automate.js" /* webpackChunkName: "component---src-pages-automate-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-clinical-operations-jsx": () => import("./../../../src/pages/clinical-operations.jsx" /* webpackChunkName: "component---src-pages-clinical-operations-jsx" */),
  "component---src-pages-computer-vision-jsx": () => import("./../../../src/pages/computer-vision.jsx" /* webpackChunkName: "component---src-pages-computer-vision-jsx" */),
  "component---src-pages-customers-jsx": () => import("./../../../src/pages/customers.jsx" /* webpackChunkName: "component---src-pages-customers-jsx" */),
  "component---src-pages-data-operations-jsx": () => import("./../../../src/pages/data-operations.jsx" /* webpackChunkName: "component---src-pages-data-operations-jsx" */),
  "component---src-pages-dicom-nifti-jsx": () => import("./../../../src/pages/dicom-nifti.jsx" /* webpackChunkName: "component---src-pages-dicom-nifti-jsx" */),
  "component---src-pages-ecg-annotation-tool-jsx": () => import("./../../../src/pages/ecg-annotation-tool.jsx" /* webpackChunkName: "component---src-pages-ecg-annotation-tool-jsx" */),
  "component---src-pages-gastroenterology-js": () => import("./../../../src/pages/gastroenterology.js" /* webpackChunkName: "component---src-pages-gastroenterology-js" */),
  "component---src-pages-glossary-jsx": () => import("./../../../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-government-js": () => import("./../../../src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-healthcare-js": () => import("./../../../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-histology-js": () => import("./../../../src/pages/histology.js" /* webpackChunkName: "component---src-pages-histology-js" */),
  "component---src-pages-image-annotation-jsx": () => import("./../../../src/pages/image-annotation.jsx" /* webpackChunkName: "component---src-pages-image-annotation-jsx" */),
  "component---src-pages-image-segmentation-jsx": () => import("./../../../src/pages/image-segmentation.jsx" /* webpackChunkName: "component---src-pages-image-segmentation-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industry-agriculture-jsx": () => import("./../../../src/pages/industry/agriculture.jsx" /* webpackChunkName: "component---src-pages-industry-agriculture-jsx" */),
  "component---src-pages-industry-computer-vision-jsx": () => import("./../../../src/pages/industry/computer-vision.jsx" /* webpackChunkName: "component---src-pages-industry-computer-vision-jsx" */),
  "component---src-pages-industry-defense-jsx": () => import("./../../../src/pages/industry/defense.jsx" /* webpackChunkName: "component---src-pages-industry-defense-jsx" */),
  "component---src-pages-industry-energy-jsx": () => import("./../../../src/pages/industry/energy.jsx" /* webpackChunkName: "component---src-pages-industry-energy-jsx" */),
  "component---src-pages-industry-healthcare-jsx": () => import("./../../../src/pages/industry/healthcare.jsx" /* webpackChunkName: "component---src-pages-industry-healthcare-jsx" */),
  "component---src-pages-industry-insurance-jsx": () => import("./../../../src/pages/industry/insurance.jsx" /* webpackChunkName: "component---src-pages-industry-insurance-jsx" */),
  "component---src-pages-industry-life-sciences-jsx": () => import("./../../../src/pages/industry/life-sciences.jsx" /* webpackChunkName: "component---src-pages-industry-life-sciences-jsx" */),
  "component---src-pages-industry-logistics-jsx": () => import("./../../../src/pages/industry/logistics.jsx" /* webpackChunkName: "component---src-pages-industry-logistics-jsx" */),
  "component---src-pages-industry-manufacturing-jsx": () => import("./../../../src/pages/industry/manufacturing.jsx" /* webpackChunkName: "component---src-pages-industry-manufacturing-jsx" */),
  "component---src-pages-industry-media-entertainment-jsx": () => import("./../../../src/pages/industry/media-entertainment.jsx" /* webpackChunkName: "component---src-pages-industry-media-entertainment-jsx" */),
  "component---src-pages-industry-retail-jsx": () => import("./../../../src/pages/industry/retail.jsx" /* webpackChunkName: "component---src-pages-industry-retail-jsx" */),
  "component---src-pages-industry-sports-jsx": () => import("./../../../src/pages/industry/sports.jsx" /* webpackChunkName: "component---src-pages-industry-sports-jsx" */),
  "component---src-pages-industry-technology-software-jsx": () => import("./../../../src/pages/industry/technology-software.jsx" /* webpackChunkName: "component---src-pages-industry-technology-software-jsx" */),
  "component---src-pages-learning-hub-jsx": () => import("./../../../src/pages/learning-hub.jsx" /* webpackChunkName: "component---src-pages-learning-hub-jsx" */),
  "component---src-pages-lp-product-hunt-jsx": () => import("./../../../src/pages/lp/product-hunt.jsx" /* webpackChunkName: "component---src-pages-lp-product-hunt-jsx" */),
  "component---src-pages-machine-learning-jsx": () => import("./../../../src/pages/machine-learning.jsx" /* webpackChunkName: "component---src-pages-machine-learning-jsx" */),
  "component---src-pages-micro-models-jsx": () => import("./../../../src/pages/micro-models.jsx" /* webpackChunkName: "component---src-pages-micro-models-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-radiology-js": () => import("./../../../src/pages/radiology.js" /* webpackChunkName: "component---src-pages-radiology-js" */),
  "component---src-pages-request-demo-jsx": () => import("./../../../src/pages/request-demo.jsx" /* webpackChunkName: "component---src-pages-request-demo-jsx" */),
  "component---src-pages-sar-jsx": () => import("./../../../src/pages/sar.jsx" /* webpackChunkName: "component---src-pages-sar-jsx" */),
  "component---src-pages-support-plans-jsx": () => import("./../../../src/pages/support-plans.jsx" /* webpackChunkName: "component---src-pages-support-plans-jsx" */),
  "component---src-pages-surgical-ai-jsx": () => import("./../../../src/pages/surgical-ai.jsx" /* webpackChunkName: "component---src-pages-surgical-ai-jsx" */),
  "component---src-pages-terms-and-conditions-uk-jsx": () => import("./../../../src/pages/terms-and-conditions-uk.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-uk-jsx" */),
  "component---src-pages-terms-and-conditions-uk-march-2021-js": () => import("./../../../src/pages/terms-and-conditions-uk-march-2021.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-uk-march-2021-js" */),
  "component---src-pages-terms-and-conditions-uk-november-2023-jsx": () => import("./../../../src/pages/terms-and-conditions-uk-november-2023.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-uk-november-2023-jsx" */),
  "component---src-pages-terms-and-conditions-us-jsx": () => import("./../../../src/pages/terms-and-conditions-us.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-us-jsx" */),
  "component---src-pages-terms-and-conditions-us-march-2021-js": () => import("./../../../src/pages/terms-and-conditions-us-march-2021.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-us-march-2021-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-try-it-free-jsx": () => import("./../../../src/pages/try-it-free.jsx" /* webpackChunkName: "component---src-pages-try-it-free-jsx" */),
  "component---src-pages-ultrasound-js": () => import("./../../../src/pages/ultrasound.js" /* webpackChunkName: "component---src-pages-ultrasound-js" */),
  "component---src-pages-webinars-jsx": () => import("./../../../src/pages/webinars.jsx" /* webpackChunkName: "component---src-pages-webinars-jsx" */),
  "component---src-pages-workflows-jsx": () => import("./../../../src/pages/workflows.jsx" /* webpackChunkName: "component---src-pages-workflows-jsx" */),
  "component---src-templates-author-detail-js": () => import("./../../../src/templates/author_detail.js" /* webpackChunkName: "component---src-templates-author-detail-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog_detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-competitor-landing-page-js": () => import("./../../../src/templates/competitor_landing_page.js" /* webpackChunkName: "component---src-templates-competitor-landing-page-js" */),
  "component---src-templates-events-landing-page-js": () => import("./../../../src/templates/events_landing_page.js" /* webpackChunkName: "component---src-templates-events-landing-page-js" */),
  "component---src-templates-glossary-detail-page-js": () => import("./../../../src/templates/glossary_detail_page.js" /* webpackChunkName: "component---src-templates-glossary-detail-page-js" */),
  "component---src-templates-itk-comp-lp-js": () => import("./../../../src/templates/itk-comp-lp.js" /* webpackChunkName: "component---src-templates-itk-comp-lp-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing_page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-learning-hub-detail-js": () => import("./../../../src/templates/learning_hub_detail.js" /* webpackChunkName: "component---src-templates-learning-hub-detail-js" */),
  "component---src-templates-new-competitor-landing-page-js": () => import("./../../../src/templates/new_competitor_landing_page.js" /* webpackChunkName: "component---src-templates-new-competitor-landing-page-js" */),
  "component---src-templates-page-v-3-js": () => import("./../../../src/templates/page_v3.js" /* webpackChunkName: "component---src-templates-page-v-3-js" */),
  "component---src-templates-rlhf-js": () => import("./../../../src/templates/rlhf.js" /* webpackChunkName: "component---src-templates-rlhf-js" */),
  "component---src-templates-try-it-free-js": () => import("./../../../src/templates/try-it-free.js" /* webpackChunkName: "component---src-templates-try-it-free-js" */),
  "component---src-templates-virtual-event-landing-page-js": () => import("./../../../src/templates/virtual_event_landing_page.js" /* webpackChunkName: "component---src-templates-virtual-event-landing-page-js" */),
  "component---src-templates-webinar-detail-page-js": () => import("./../../../src/templates/webinar_detail_page.js" /* webpackChunkName: "component---src-templates-webinar-detail-page-js" */),
  "component---src-templates-whitepaper-js": () => import("./../../../src/templates/whitepaper.js" /* webpackChunkName: "component---src-templates-whitepaper-js" */)
}

